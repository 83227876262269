export const EN = {
  UnexpectedError: "Something went wrong :( Please try refreshing the page",
  CURRENT_LANGUAGE: "English",

  Claims_Administration: "Administration",
  Claims_Energy: "Energy",
  "Claims_Extend role to all organizations": "Extend role to all organizations",
  Claims_Invoices: "Invoices",
  Claims_Knowledge: "Knowledge Center",
  Claims_NetMW: "NetMW",
  Claims_PortfolioPhysical: "Portfolio Physical",
  Claims_PortfolioFinancial: "Portfolio Financial",
  Claims_Developer: "Developer",
  Claims_Documents: "Documents",
  Claims_InvoiceMaterials: "Invoice Materials",
  Claims_DocumentsForAll: "Documents for all organizations",
  Claims_Flexibility: "Flexibility",
  Claims_IncomingData: "Incoming data",

  Contract: "Contract",
  Counterpart: "Counterpart",
  Courtage: "Courtage",
  Comment: "Comment",
  Contact: "Contact",
  "Created by": "Created by",
  Created: "Created",
  "Expiry date": "Expiry date",
  EXPIRED: "EXPIRED",

  "You have no api keys.": "You have no api keys.",
  "Error: missing or faulty headers: ": "Error: missing or faulty headers: ",
  "Error: missing or faulty data: ": "Error: missing or faulty data: ",
  "Creating import position failed": "Creating import position failed",
  "Removing import position was successful":
    "Removing import position was successful",
  "Removing import position failed": "Removing import position failed",
  Paste: "Paste",
  Reset: "Reset",
  "Paste not supported by browser, use CTRL+V":
    "Paste not supported by browser, use CTRL+V",
  "Could not fetch coordinates for plants":
    "Could not fetch coordinates for plants",

  hedgeVolume: "Hedge Volume",
  Forecast: "Forecast",
  mandateMinVolume: "MIN",
  mandateMaxVolume: "MAX",
  "Without imported position": "Without imported position",
  "With imported position": "With imported position",
  "Only imported position": "Only imported position",

  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",

  All: "All",
  Videos: "Videos",
  Articles: "Articles",
  Webinars: "Webinars",

  "My bookmarks": "My bookmarks",
  Bookmark: "Bookmark",

  Loading: "Loading",
  "No articles available": "No articles available",

  Users: "Users",
  "User Roles": "User Roles",
  Roles: "Roles",
  "Users & Roles": "Users & Roles",
  Developer: "Developer",
  "Edit Profile": "Edit Profile",
  Logout: "Logout",
  "User administration": "User administration",
  "Roles administration": "Roles administration",

  Cancel: "Cancel",
  Close: "Close",
  Done: "Done",
  Add: "Add",
  "Save Changes": "Save Changes",
  Save: "Save",

  "User added!": "User added!",
  "User updated": "User updated",
  "User update failed": "User update failed",
  "Filter by name": "Filter by name",
  "Add new user": "Add new user",
  "Users in": "Users in",

  "Add new plant": "Add new plant",
  "Edit plant": "Edit plant",
  "Price area": "Price area",
  "Price areas": "Price areas",
  "Meteologica Id": "Meteologica Id",

  Ice: "Ice",
  Curtailment: "Curtailment",
  Power: "Power",
  "Maximum power": "Maximum power",

  "Coordinates (latitude)": "Coordinates (latitude)",
  "Coordinates (longitude)": "Coordinates (longitude)",

  "User Roles in": "User Roles in",
  "Search by role": "Search by role",
  "Add new role": "Add new role",

  "Add new client": "Add new client",
  Clients: "Clients",
  Client: "Client",
  "Organization number": "Organization number",
  "NAV ID": "NAV ID",
  "BFUS ID": "BFUS ID",
  "EDIEL ID": "EDIEL ID",

  "Edit client": "Edit client",
  "Search client": "Search client",
  "Client type": "Client type",

  "Search by name": "Search by name",

  Welcome: "Welcome",
  "Welcome to Modity": "Welcome to Modity",
  "A simple energy trade": "A simple energy trade",
  "Contact us at": "Contact us at",

  Home: "Home",
  "Knowledge Center": "Knowledge Center",
  "Read entry": "Read entry",
  "Everything you need to know": "Everything you need to know",
  "Search results for:": "Search results for:",
  "Showing:": "Showing:",
  "Looking for anything particular?": "Looking for anything particular?",
  Search: "Search",
  Invoices: "Invoices",
  "All invoices in one place": "All invoices in one place",
  "Latest news": "Latest news",
  "See all news": "See all news",
  "There are no news yet": "There are no news yet",
  "Latest invoices": "Latest invoices",
  "See all invoices": "See all invoices",
  "Search by invoice number": "Search by invoice number",
  "No invoices found": "No invoices found",

  Email: "Email",
  "First name": "First name",
  "Last name": "Last name",
  "Phone number": "Phone number",

  "Daily comments": "Daily comments",
  "Subscribed to daily comments": "Subscribed to daily comments",
  "Edit profile": "Edit profile",
  Role: "Role",

  User: "User",
  Name: "Name",
  "Assigned role": "Assigned role",
  Delete: "Delete",
  Remove: "Remove",
  "Last active": "Last active",

  Edit: "Edit",
  "Role name": "Role name",
  "What access rights should this role provide":
    "What access rights should this role provide",
  "Role is used by": "Role is used by",
  "Add role": "Add role",
  "Role updated": "Role updated",
  "Role update failed": "Role update failed",
  "Role added": "Role added",
  "Adding role failed": "Adding role failed",
  "Remove role": "Remove role",
  "Role deleted": "Role deleted",
  "Failed to remove role": "Failed to remove role",

  "Remove user": "Remove user",
  "Remove from organization": "Remove from organization",
  "Are you sure you want to remove": "Are you sure you want to remove",
  "was removed": "was removed",
  "User added": "User added",
  "Adding user failed": "Adding user failed",
  "User deleted": "User deleted",
  "Failed to remove user": "Failed to remove user",
  "Remove from all organizations": "Remove from all organizations",

  "No data found": "No data found",
  "You are not part of any organization, contact your administrator for help.":
    "You are not part of any organization, contact your administrator for help.",

  "Dont show again": "Dont show again",
  Dismiss: "Dismiss",
  "Read more": "Read more",

  "Updating your profile failed": "Updating your profile failed",

  "Sorry, couldn't find what you were looking for":
    "Sorry, couldn't find what you were looking for",
  Please: "Please",
  refresh: "refresh",
  or: "or",
  "go back": "go back",

  "Load more": "Load more",
  "select image": "select image",

  Heading_ProductionConsumption: "Consumption and Production",
  Heading_SpotPrice: "Spot price",

  Heading_CustomerData: "Provided Data",
  Overview: "Overview",
  Plant: "Plant",
  Plants: "Plants",
  Planning: "Planning",

  "Preliminary production": "Preliminary production",
  "Preliminary consumption": "Preliminary consumption",
  "Production total": "Production total",
  "Consumption total": "Consumption total",
  "Market data": "Market data",
  "Nordic System Price": "Nordic System Price",
  "Cannot get Production": "Cannot get Production",
  "Cannot get Consumption": "Cannot get Consumption",
  "Cannot get Market data": "Cannot get Market data",
  Production: "Production",
  Consumption: "Consumption",
  "Consumption and Production": "Consumption and Production",
  PlanConsumption: "Planned Consumption",
  PlanProduction: "Planned Production",
  Interval: "Interval",

  Select: "Select",
  "available selections": "available selections",
  "Select max": "Select max",

  Time: "Time",
  Yes: "Yes",

  "ALL FACILITIES DURING PERIOD": "ALL FACILITIES DURING PERIOD",
  "ALL PLANTS DURING PERIOD": "ALL PLANTS DURING PERIOD",
  "ALL REGIONS DURING PERIOD": "ALL REGIONS DURING PERIOD",
  "YEAR OVERVIEW FOR": "YEAR OVERVIEW FOR",
  PLANT: "PLANT",
  PERIOD: "PERIOD",
  "PRICE OUTCOME": "PRICE OUTCOME",
  "Weather information": "Weather information",
  "Meter points": "Meter points",

  Temperature: "Temperature",
  Source: "Source",
  "Temperature data from": "Temperature data from",
  "No temperature station found": "No temperature station found",
  "No temperature data found": "No temperature data found",
  "Wind data from": "Wind data from",
  "No wind data found": "No wind data found",
  "No wind station found": "No wind station found",
  "No data available for the selected period.":
    "No data available for the selected period.",

  "Select plant": "Select plant",
  "Please select a plant": "Please select a plant",
  "Getting list of plants": "Getting list of plants",
  "No plants found": "No plants found",
  From: "From",
  To: "To",
  "To (inclusive)": "To (inclusive)",
  "Show previous months": "Show previous months",
  "ALL MEASURE POINTS": "ALL MEASURE POINTS",

  Menu_Start: "Home",
  Menu_Files: "Files",
  Menu_Invoices: "Invoices",
  Menu_Knowledge: "Knowledge Center",
  Menu_Energy: "Consumption and Production",
  Menu_CustomerData: "Customer Provided Data",
  Menu_Settings: "Settings",
  Menu_ModitySettings: "Modity Settings",
  Menu_NetMW: "Net MW",
  Menu_Portfolio: "Portfolio",
  Menu_Developer: "Developer",

  Turbines: "Turbines",
  "% of max effect": "% of max effect",
  Period: "Period",

  "Profiled consumption": "Profiled consumption",
  "Metered consumption": "Metered consumption",

  "Show all plants": "Show all plants",
  "Show all measurands": "Show all measurands",
  "Show all turbines": "Show all turbines",
  "Profiled production": "Profiled production",
  "Metered production": "Metered production",
  AND: "AND",

  HourlyStandardsProduction: "Hourly Production",
  HourlyStandardsConsumption: "Hourly Consumption",

  productionHourlyPriceArea1: "SE1",
  productionHourlyPriceArea2: "SE2",
  productionHourlyPriceArea3: "SE3",
  productionHourlyPriceArea4: "SE4",
  consumptionHourlyPriceArea1: "SE1",
  consumptionHourlyPriceArea2: "SE2",
  consumptionHourlyPriceArea3: "SE3",
  consumptionHourlyPriceArea4: "SE4",

  consumptionMonthly: "Månadsmätt förbrukning",
  MonthlyStandards: "Monthly Consumption",
  monthlyConsumptionStandard: "Monthly Consumption",
  hourlyConsumptionStandard: "Hourly Consumption",

  "File is too large, has to be maximum 195kB":
    "File is too large, has to be maximum 195kB",

  "Invalid email": "Invalid email: Enter only account name in the field",
  "This field is required": "This field is required",
  "Maximum 64 characters": "Maximum 64 characters",
  "Numbers only": "Numbers only",
  "The name already exists, a unique name is required":
    "The name already exists, a unique name is required",
  "Set meteologica id": "Set facility id",

  supplier: "Supplier",
  balanceController: "Balance controller",

  "Add turbine": "Add turbine",
  "Add new turbine": "Add new turbine",
  "Edit turbine": "Edit turbine",
  "Search by turbine": "Search by turbine",
  Unit: "Unit",

  Measurands: "Measurands",
  "Add measurand": "Add measurand",
  "Add new measurand": "Add new measurand",
  "Edit measurand": "Edit measurand",
  "Search by measurand": "Search by measurand",
  Type: "Type",
  "Invalid ID": "The value is not a valid ID",

  "Measurand added": "Measurand added",
  "Adding measurand failed": "Adding measurand failed",
  "Measurand deleted": "Measurand deleted",
  "Deleting measurand failed": "Deleting measurand failed",
  "Measurand updated": "Measurand updated",
  "Updating measurand failed": "Updating measurand failed",

  "Turbine added": "Turbine added",
  "Adding turbine failed": "Adding turbine failed",
  "Turbine deleted": "Turbine deleted",
  "Deleting turbine failed": "Deleting turbine failed",
  "Turbine updated": "Turbine updated",
  "Updating turbine failed": "Updating turbine failed",

  "Plant added": "Plant added",
  "Adding plant failed": "Adding plant failed",
  "Plant deleted": "Plant deleted",
  "Deleting plant failed": "Deleting plant failed",
  "Plant updated": "Plant updated",
  "Updating plant failed": "Updating plant failed",
  "Cannot delete plant with either measurands or turbines":
    "Cannot delete plant with either measurands or turbines",

  "Organization added": "Organization added",
  "Adding organization failed": "Adding organization failed",
  "Organization deleted": "Organization deleted",
  "Deleting organization failed": "Deleting organization failed",
  "Organization updated": "Organization updated",
  "Updating organization failed": "Updating organization failed",

  Effect: "Effect",
  "Sum of max effects from all turbines":
    "Sum of max effects from all turbines",
  "Unit for maximum effect": "Unit for maximum effect",

  "Portfolio report": "Portfolio report",
  "Import Positions": "Import Positions",
  "Import ELC Positions": "Import ELC Positions",
  "Import GO Positions": "Import GO positions",

  TABS_TOTAL: "Total",
  TABS_PHYSICAL: "Physical",
  TABS_FINANCIAL: "Financial",
  Total: "Total",
  Physical: "Physical",

  TOTAL_TABLE_TITLE_PHYSICAL: "Physical",
  TOTAL_TABLE_TITLE_FINANCIAL: "Financial",
  Financial: "Financial",
  Month: "Month",
  Volume: "Volume",
  "Cost(-)/Revenue(+)": "Cost(-)/Revenue(+)",
  Fees: "Fees",
  "Hedged volume": "Hedged volume",
  "Trading fees": "Trading fees",
  "Price per unit": "Price per unit",
  "öre/kWh": "öre/kWh",
  TOTAL_TABLE_TOTAL: "Total",

  Planned: "Planned",
  Outcome: "Outcome",
  Measured: "Measured",
  Imbalance: "Imbalance",
  Sum: "Sum",
  Fee: "Fee",

  Price: "Price",
  price: "price",
  Currency: "Currency",

  SUBPAGE_OUTCOME: "Settlement",
  SUBPAGE_HEDGE_REPORT: "Hedge report",
  SUBPAGE_INDEX_POOL: "Index pool",
  SUBPAGE_TRANSACTION_LIST: "Transaction list",
  SUBPAGE_INDEX_DETAILS: "Index details",
  SUBPAGE_GREEN_PRODUCTS: "Green products",

  Product: "Product",
  "Hedged price": "Hedged price",
  "Calculated result for": "Calculated result for",
  "Summary of trading fees for": "Summary of trading fees for",
  "Purchased volume": "Purchased volume",
  "Fees (NASDAQ)": "Fees (NASDAQ)",
  "Download report": "Download report",

  "Open volume": "Open volume",
  "Market price": "Market price",
  "Forecast price": "Forecast price",
  "% Hedged": "% Hedged",
  "% Min": "% Min",
  "% Max": "% Max",
  "Go Volume": "Go Volume",
  "Go Agreement Price": "Go Agreement Price",
  "Elc Volume": "Elc Volume",
  "Elc Agreement Price": "Elc Agreement Price",
  "Total Agreement Price": "Total Agreement Price",

  hedge: "Hedge",
  targetVolume: "Target Volume",
  utilizedVolume: "Consumption and Production",
  Order: "Order",
  "Trade date": "Trade date",
  Settlement: "Settlement",
  "Start date": "Start date",
  "End date": "End date",
  Comment1: "Comment 1",
  Comment2: "Comment 2",
  ID: "ID",
  Status: "Status",

  "Show/Hide columns": "Show/Hide columns",
  "Visible columns": "Visible columns",

  "Actual produced or consumed volume": "Actual produced or consumed volume",
  "With imbalance price": "With imbalance price",
  "Trading fees and government fees": "Trading fees and government fees",

  Updated: "Updated",
  Contacts: "Contacts",
  "Contact us": "Contact us",
  "The team, which helps you simplify your energy business":
    "The team, which helps you simplify your energy business",
  "Other matters": "Other matters",
  "Contact added": "Contact added",
  "Adding contact failed": "Adding contact failed",
  "Contact updated": "Contact updated",
  "Remove contact": "Remove contact",
  "Contact removed": "Contact removed",
  "Contact update failed": "Contact update failed",
  "Contact deleted": "Contact deleted",
  "Failed to remove contact": "Failed to remove contact",
  "Add new contact person": "Add new contact person",
  Function: "Function",
  "Select a function": "Select a function",
  People: "People",
  "Edit contact person": "Edit contact person",
  "Select a person": "Select a person",

  SINGULAR_SELECTED: "selected",
  MULTIPLE_SELECTED: "selected",

  "Analysis Manager": "Analysis Manager",
  "Head of Business Area Gas": "Head of Business Area Gas",
  "Head of front office and Business Area Trading":
    "Head of front office and Business Area Trading",
  "Physical Trading Manager": "Physical Trading Manager",
  "Head of Middle Office and Business Area Power":
    "Head of Middle Office and Business Area Power",
  "Chief Disruption Officer": "Chief Disruption Officer",
  "Chief Financial Officer": "Chief Financial Officer",
  "Compliance Officer": "Compliance Officer",
  "Physical Settlement": "Physical Settlement",
  "Financial Manager": "Financial Manager",
  "Financial Clearing and Settlement": "Financial Clearing and Settlement",
  "Financial Trader": "Financial Trader",
  "Physical Trader": "Physical Trader",
  "HR Manager": "HR Manager",
  "IT Manager": "IT Manager",
  "Key Account Manager": "Key Account Manager",
  "Communications Manager": "Communications Manager",
  "Communications Officer": "Communications Officer",
  "Facility Assistant": "Facility Assistant",
  "Key Account Manager (Partner)": "Key Account Manager - Kundansvarig Partner",
  "Quantitative Developer": "Quantitative Developer",
  "Lead Architect": "Lead Architect",
  "Marketing Assistant": "Marketing Assistant",
  "Portfolio Manager": "Portfolio Manager",
  "Project Manager and Sustainability Manager":
    "Project Manager and Sustainability Manager",
  Accountant: "Accountant",
  "Risk Manager": "Risk Manager",
  "Systems Manager": "Systems Manager",
  "Director Business Development": "Director Business Development",
  "Treasury Controller": "Treasury Controller",
  CEO: "CEO",

  "Search by portfolio report configuration":
    "Search by portfolio report configuration",
  "Add new portfolio report configuration":
    "Add new portfolio report configuration",
  "Portfolio reports": "Portfolio reports",
  "Portfolio report configuration added":
    "Portfolio report configuration added",
  "Adding portfolio report configuration failed":
    "Adding portfolio report configuration failed",
  "Portfolio report configuration deleted":
    "Portfolio report configuration deleted",
  "Deleting portfolio report configuration failed":
    "Deleting portfolio report configuration failed",
  "Portfolio report configuration updated":
    "Portfolio report configuration updated",
  "Updating portfolio report configuration failed":
    "Updating portfolio report configuration failed",
  Portfolio: "Portfolio",
  Portfolios: "Portfolios",
  "Confirm name to delete": "Confirm name to delete",
  "The names do not match": "The names do not match",
  "Configure portfolio reports": "Configure portfolio reports",
  "All products": "All products",
  "All portfolios": "All portfolios",
  "Exchange Rate": "Exchange Rate",
  Summary: "Summary",

  "This name is already taken and it has to be unique":
    "This name is already taken and it has to be unique",
  NetMW: "NetMW",
  "Forecast Volume": "Forecast Volume",

  Productname: "Productname",
  "Paste copied data from excel": "Paste copied data from excel",
  "Pasted data": "Pasted data",
  History: "History",
  "Missing headers for posted data": "Missing headers for posted data",
  "Trade period": "Trade period",
  "Start of trade period": "Start of trade period",
  "Start of hedge period": "Start of hedge period",
  "Forecast created": "Forecast created",
  "Faulty data pasted": "Faulty data pasted",
  "Creating forecast failed": "Creating forecast failed",
  "Mandate created": "Mandate created",
  "Creating mandate failed":
    "Creating mandate failed! Ensure only percentage values are entered.",
  "Forecast History": "Forecast History",
  "Forecast deleted": "Forecast deleted",
  "Delete forecast failed": "Delete forecast failed",
  "Mandate History": "Mandate History",
  "Mandate deleted": "Mandate deleted",
  "Delete mandate failed": "Delete mandate failed",
  Year: "Year",
  "Configure forecast, mandate and import position":
    "Configure forecast, mandate and import position",
  "Portfolio configuration": "Portfolio configuration",
  Mandate: "Mandate",
  "Import Position": "Import Position",
  Date: "Date",

  "No products where found": "No products where found",
  "There is nothing to show": "There is nothing to show",
  Approved: "Approved",
  "Belongs to third party": "Belongs to third party",
  "User Agreement": "User Agreement",

  "Select all": "Select all",
  "Unselect all": "Unselect all",

  Tomorrow: "Tomorrow",
  Today: "Today",
  Market: "Market",
  Weather: "Weather",
  "System Price": "System Price",
  "Spot and forward prices": "Spot and forward prices",
  Prices: "Prices",
  "Spot prices": "Spot prices",

  "Target volume": "Target volume",
  "Delivery period": "Delivery period",
  Delivery: "Delivery",
  Target: "Target",
  Hedged: "Hedged",
  Start: "Start",
  End: "End",
  "Index orders": "Index orders",
  Buy: "Buy",
  Sell: "Sell",
  Unknown: "Unknown",
  Upcoming: "Upcoming",
  New: "New",
  Hedging: "Hedging",
  Awaiting: "Awaiting",
  Completed: "Completed",
  Terminated: "Terminated",
  "Until further notice": "Until further notice",
  "Financial Price per unit": "Financial Price per unit",
  "Third Party Portfolios": "Third Party Portfolios",
  "Third party client": "Third party client",
  Actor: "Actor",
  Export: "Export",
  rows: "rows",
  "Remove rows": "Remove rows",
  "Successfully saved import positions": "Successfully saved import positions",

  Guide: "Guide",
  "Key Management": "Key Management",
  "This is the only time you can copy the key, please copy it now and save it in a safe place.":
    "This is the only time you can copy the key, please copy it now and save it in a safe place.",
  "API-Keys": "API-Keys",
  "Generate new key": "Generate new key",
  "Your new API-key": "Your new API-key",
  "Copied API key": "Copied API key",
  "Enter key name": "Enter key name",
  Revoke: "Revoke",
  ConfirmRevoke: "Are you sure you want to revoke the token?",
  Create: "Create",

  Authentication: "Authentication",
  Limitations: "Limitations",
  "Another option to try out the API is to use curl:":
    "Another option to try out the API is to use curl:",
  "Getting started with Modity REST API":
    "Getting started with Modity REST API",
  'An API Key is required to use the API. You can create a key by going to the "Key management" tab and clicking on the "Create new API key" button.':
    'An API Key is required to use the API. You can create a key by going to the "Key management" tab and clicking on the "Create new API key" button.',
  "Note that you must copy and save the key in a safe place immediately as it cannot be seen again after the popup has been closed. If you lose the key, you will need to create a new one.":
    "Note that you must copy and save the key in a safe place immediately as it cannot be seen again after the popup has been closed. If you lose the key, you will need to create a new one.",
  'A key has an expiry date connected to it, which you can see by going to the "Key Management" tab and expanding the "Current Key" pane. Here you can also revoke the key. If you create a new key the old key will become invalid, effective immediately.':
    'A key has an expiry date connected to it, which you can see by going to the "Key Management" tab and expanding the "Current Key" pane. Here you can also revoke the key. If you create a new key the old key will become invalid, effective immediately.',
  "The API is documented using Swagger": "The API is documented using Swagger",
  'Using Swagger, you can try out the API as a developer. The endpoints require the API key and those parameters that are marked as "required".':
    'Using Swagger, you can try out the API as a developer. The endpoints require the API key and those parameters that are marked as "required".',
  "The API is limited to receiving a maximum of 100 calls / minute per user.":
    "The API is limited to receiving a maximum of 100 calls / minute per user.",

  "This website uses cookies": "This website uses cookies",
  "The information is used to improve our services. Read more in our":
    "The information is used to improve our services. Read more in our",
  "Cookie Policy": "Cookie Policy",

  Accept: "Accept",
  "Horizon counterparties": "Horizon counterparties",
  "Electricity Certificates": "Electricity Certificates",

  forecast: "Forecast",
  buyQuantity: "Purchased quantity",
  buyPriceAvg: "Purchased price",
  sellQuantity: "Sold quantity",
  sellPriceAvg: "Sold price",
  totalQuantity: "Total quantity",
  totalPriceAvg: "Average price",
  totalPrice: "Cost",
  quotaPercent: "Quota obligation %",
  quotaQuantity: "Needs according to forecast",
  buyForecastPercent: "Purchased quantity/forecast %",
  sellForecastPercent: "Sold quantity/forecast %",
  importedQuantity: "Total imported positions quantity",
  importedPriceAvg: "Total imported positions price",
  remainingQuantity: "Remaining certificates needed",
  remainingPercent: "Remaining needs %",
  importedSellQuantity: "Sold imported positions quantity",
  importedBuyQuantity: "Purchased imported positions quantity",
  importedSellPriceAvg: "Sold imported positions price",
  importedBuyPriceAvg: "Purchased imported positions price",
  "Electricity certificate": "Electricity certificate",
  "ELC service": "ELC service",
  Quantity: "Quantity",

  quotaObligationVolume: "Quota obligation volume",
  quotaObligationExemptedVolume: "Quota obligation exempted volume",
  consumptionVolume: "Consumption Volume",
  Until: "Till",
  apiTokenWarning: "Obs: Användaren har en aktiv API-nyckel!",

  Resolution: "Resolution",
  Yearly: "Yearly",
  Quarterly: "Quarterly",
  Monthly: "Monthly",
  "There was an error trying to get the report":
    "There was an error trying to get the report",
  "Adding email whitelist domain failed":
    "Adding email whitelist domain failed",
  "Deleting email whitelist domain failed":
    "Deleting email whitelist domain failed",
  "Email domain whitelist": "Email domain whitelist",
  emailDomainInputLabel: "Email domain, e.g. modity.se",
  "No whitelisted email domains": "No whitelisted email domains",

  "Index product": "Index product",
  "Index pool": "Index pool",
  IndexPool: "Index pool",
  IntroductionOrder: "Introduction order",
  "Select configuration type": "Select configuration type",
  notFound: "We couldn't find what you're looking for :(",

  INDEXPOOL_OVERVIEW: "Overview",
  INDEXPOOL_ORDERS: "Orders",
  INDEXPOOL_INVOICES: "Invoices",
  Orders: "Orders",
  Key: "Key",
  IndexProductPortfolios: "Index portfolios",
  IndexProductPortfolios_limit:
    "those already in use on the client are not selectable",

  "Delivery month": "Leveransmånad",
  "First delivery month": "Första leveransmånad",
  Heading_SpotPrice_invoice_material: "Spot price",
  "Index pools": "Index pools",
  "No available options": "No available options",
  "Index Portfolios": "Index Portfolios",

  GREEN_PRODUCTS_ELC: "Electricity certificates - report",
  GREEN_PRODUCTS_GO_SUMMARY: "Guarantee of origin - summary",
  GREEN_PRODUCTS_GO: "Guarantee of origin - report",
  GREEN_PRODUCTS_GO_CONSUMPTION_FORM: "Input monthly consumption",
  Preliminary: "Preliminary",
  Final: "Final",
  "Are you sure you want to save?": "Are you sure you want to save?",
  "You can't undo this choice": "You can't undo this choice",
  "Update consumption failed": "Update consumption failed",
  "Consumption updated": "Consumption updated",
  TOTAL_UNIT_PRICE: "Total price per GO",
  TOTAL_QUANTITY: "Total GO Quantity",
  TOTAL_PRICE: "Total price",
  Amount: "Amount",
  "GO service": "GO service",
  "Guarantee of origin": "Guarantee of origin",

  Files: "Files",
  TABS_INVOICES: "Invoices",
  TABS_DOCUMENTS: "Documents",
  TABS_INVOICE_MATERIALS: "Invoice materials",
  "No documents found": "No documents found",
  Everyone: "Everyone",
  Upload: "Upload",
  "Accessible to everyone": "Accessible to all organizations in Live",
  "Upload succeeded": "Upload succeeded",
  "Upload failed": "Upload failed",
  "Document deleted": "Document deleted",
  "Failed to delete document": "Failed to delete document",
  "No invoice material found": "No invoice material found",

  "Time zone": "Time zone",
  "Availability plan": "Availability plan",
  "Availability plan template": "Availability plan template",
  AVAILABILITY_PLAN_TEMPLATE_None: "None",
  AVAILABILITY_PLAN_TEMPLATE_TimeSeriesPlant: "Time series",
  Energy_OVERVIEW: "Overview",
  Energy_PLANT: "Plant",
  Energy_AVAILABILITY: "Availability",

  AvailabilityPlanDownloadInstructions:
    "Download your template and fill it in, then upload it and verify before saving",
  "Please upload a document": "Please upload a document",
  Errors: "Errors",
  Verification: "Verification",
  "Download Template": "Download Template",
  ExcelValidationError:
    "There was an error with the uploaded document, please try again or contact Modity for more information",
  "Successfully saved Availability plan":
    "Successfully saved Availability plan",
  "Saving availability plan failed": "Saving availability plan failed",
  Found: "Found",
  "in sheet": "in sheet",
  "at cell": "at cell",
  row: "row",
  column: "column",
  expected: "expected",
  NoAvailabilityPlanTemplateMessage: "No availability plan template configured",

  preliminaryVolumeInformation: `The preliminary consumption must be completed and saved to unlock the "Final" section`,
  finalVolumeInformation: "The final consumption",
  "Total volume": "Total volume",
  "Fixed volume": "Fixed volume",
  "Preliminary consumption volume": "Preliminary consumption volume",
  "Final consumption volume": "Final consumption volume",

  planWeekError: "Needs to be between 1 and 52",
  planDayError: "Needs to be between 1 and 365",
  "Amount of weeks": "Amount of weeks",
  "Amount of days": "Amount of days",
  downloadTemplateDialogTitle: "How many days should be in the template?",
  Range: "Range",
  Continues: "Continues",
  "Reset filter": "Reset filter",
  Filter: "Filter",
  Visual: "Visual",

  "Copied chart": "Copied chart",

  "Add new period": "Add new period",
  AddPeriodDescription: "Choose a start date for the new period:",
  "Can only delete latest period": "Can only delete latest period",
  "Successfully updated factors": "Successfully updated factors",
  "Could not update factors": "Could not update factors",
  "Succesfully deleted period": "Succesfully deleted period",
  "Could not delete period": "Could not delete period",
  "Succesfully added a new period": "Succesfully added a new period",
  "Could not add a new period": "Could not add a new period",

  feeLabel_PowerReserve: "Power Reserve",
  feeLabel_NordpoolConsumption: "Nordpool Consumption Fee",
  feeLabel_NordpoolProduction: "Nordpool Production Fee",
  feeLabel_EdielConsumption: "Ediel Consumption Fee",
  feeLabel_EdielProduction: "Ediel Production Fee",
  feeLabel_ImbalanceConsumption: "Imbalance Consumption",
  feeLabel_ImbalanceProductionOther: "Imbalance Production Other",
  feeLabel_ImbalanceProductionSolar: "Imbalance Production Solar",
  feeLabel_ImbalanceProductionWind: "Imbalance Production Wind",
  feeLabel_ImbalanceProductionHydro: "Imbalance Production Hydro",
  feeLabel_ImbalanceProductionCogeneration: "Imbalance Production Cogeneration",
  feeLabel_BalanceFeeConsumptionSE1: "Balance Fee Consumption SE1",
  feeLabel_BalanceFeeConsumptionSE2: "Balance Fee Consumption SE2",
  feeLabel_BalanceFeeConsumptionSE3: "Balance Fee Consumption SE3",
  feeLabel_BalanceFeeConsumptionSE4: "Balance Fee Consumption SE4",
  feeLabel_BalanceFeeProductionOther: "Balance Fee Production Other",
  feeLabel_BalanceFeeProductionSolar: "Balance Fee Production Solar",
  feeLabel_BalanceFeeProductionWind: "Balance Fee Production Wind",
  feeLabel_BalanceFeeProductionHydro: "Balance Fee Production Hydro",
  feeLabel_BalanceFeeProductionCogeneration:
    "Balance Fee Production Cogeneration",
  feeLabel_MonthlyFee: "Monthly Fee",
  feeLabel_InterestRate: "Stibor (T/N)",
  feeLabel_Production: "Basic Production Fee",
  feeLabel_Consumption: "Basic Consumption Fee",
  imbalanceConsumptionDescription: "1 = fee, <> 1 = fee - 0,5",
  feeLabel_InterestRateProductionAddition: "Interest Rate Production Addition",
  feeLabel_InterestRateProductionDays: "Interest Rate Production Days",
  feeLabel_InterestRateConsumptionAddition:
    "Interest Rate Consumption Addition",
  feeLabel_InterestRateConsumptionDays: "Interest Rate Consumption Days",
  feeLabel_InterestRate3m: "Stibor (3m)",
  interestRateDescription: "1 = active, 0 = inactive",
  DataGroups: "Customer data grouping",
  DataGroups_add: "Add data group",
  DataGroups_created: "Created a data group",
  DataGroups_created_failed: "Failed to create data group",
  DataGroups_updated: "Updated data group: ",
  DataGroups_updated_failed: "Failed to update data group: ",
  DataGroups_delete: "Deleted data group: ",
  DataGroups_delete_failed: "Failed to delete data group: ",
  DataGroups_edit: "Edit data grupp",
  DataGroups_Production_MFU: "MFU series for Productiondata",
  DataGroups_Consumption_MFU: "MFU series for Consumptiondata",
  Hydro: "Hydro",
  Solar: "Solar",
  Wind: "Wind",
  Bio: "Bio",
  Nuclear: "Nuclear",
  GecWind: "GEC wind",
  GecSolar: "GEC solar",
  GecHydro: "GEC hydro",
  GecBio: "GEC bio",
  Organization: "Organization",
  ImpersonatedRole: "Impersonated role",
  EndImpersonation: "End impersonated role",
  UseImpersonatedRole: "Use imperonated role",

  "Production Types": "Production Types",
  Ongoing: "Ongoing",
  value: "value",
  "invalid date format": "invalid date format",
  "invalid number value": "invalid number value",
  "error message": "error message",
  "Getting list of data groups": "Getting list of data groups",
  "Successfully saved data plans": "Successfully saved data plans",
  "Saving data plans failed": "Saving data plans failed",
  "Select Data Group": "Select data group",
  "No datagroups found": "No datagroups found",
  "Missing timeseries": "Missing timeseries",
  MissingHoursError:
    "The uploaded plan contains missing time intervals and cannot be saved. Please correct the issues and try again.",
  M15: "15 minutes",
  H1: "Hourly",
  IncomingDataPlan: "Data plans",
  UploadIncomingDataPlan: "Upload data plan",
  ProductionConsumption: "Production/Consumption",
  IncomingPeriod: "Select date range",
  BatteryAvailability: "Battery availability",
  "Select resource": "Select resource",
  TABS_PRODUCTION_CONSUMPTION: "Production/Consumption",
  TABS_BATTERY_AVAILABILITY: "Battery availability",
  DataPlanDownloadInstructions: "Upload and verify before saving",
  MissMatchPeriodSize:
    "The timeseries does not match the expected resolution that is specified in data group",
  Fcr_D_Up: "FcrD-Up",
  Fcr_D_Down: "FcrD-Down",
  Intraday: "Intraday",
  ToggleBatteryAvailabilityPlanEnabled: "Battery availability",
  UploadBatteryAvailability: "Upload battery availability",
  BatterAvailabilityPlanTemplate: "Battery availability is not configured",
  BatteryAvailabilityValidationErrors:
    "There was an error with the uploaded document, please try again or contact Modity for more information",
  EmptyAvailabilityPlan: "The battery availability plan is empty",
  ToBeforeFrom: "To time stamp is less than From",
  NegativeValue: "The value for available power is negative",
  OverlappingDateRange: "Overlap in time series",
  TimeGap: "Time series is missing",
  MaxPowerExceeded: "Max available power exceeded",
  PastDateTime: "Time series is in the past",
  StartDate: "Start date",
  PrequalifiedEffect: "Prequalified effect",
  PrequalifiedEffectUp: "Prequalified effect up",
  PrequalifiedEffectDown: "Prequalified effect down",
};
