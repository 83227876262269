/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Agreement = {
  __typename: "Agreement";
  content: Scalars["String"]["output"];
};

export type Article = {
  author: Maybe<Author>;
  content: Maybe<Scalars["String"]["output"]>;
  contentType: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  featured: Maybe<Scalars["Boolean"]["output"]>;
  firstPublishedAt: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  preamble: Maybe<Scalars["String"]["output"]>;
  publicationStartDate: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["String"]["output"]>;
};

export type Author = {
  __typename: "Author";
  avatar: Maybe<Image>;
  firstName: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
};

export type AvailableFeatures = {
  __typename: "AvailableFeatures";
  clientAdministration: Maybe<Scalars["Boolean"]["output"]>;
  portfolio: Maybe<Scalars["Boolean"]["output"]>;
};

export type AvailableIndexProductPortfolio = {
  __typename: "AvailableIndexProductPortfolio";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  usedByPortfolioConfiguration: Maybe<Scalars["String"]["output"]>;
};

export type AvailableMeasurand = {
  __typename: "AvailableMeasurand";
  belongsToThirdParty: Maybe<Scalars["Boolean"]["output"]>;
  measurandId: Maybe<Scalars["String"]["output"]>;
  measurandName: Maybe<Scalars["String"]["output"]>;
  measurandType: Maybe<Scalars["String"]["output"]>;
  plantName: Maybe<Scalars["String"]["output"]>;
  priceArea: Maybe<Scalars["String"]["output"]>;
};

export type AvailableOptions = {
  __typename: "AvailableOptions";
  availableAllegroCounterparties: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  availableIndexProductPortfolios: Maybe<
    Array<Maybe<AvailableIndexProductPortfolio>>
  >;
  availableMeasurands: Maybe<Array<Maybe<AvailableMeasurand>>>;
  availablePortfolios: Maybe<Array<Maybe<AvailablePortfolio>>>;
  availableThirdPartyPortfolios: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
};

export type AvailablePortfolio = {
  __typename: "AvailablePortfolio";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type BatteriesOrganization = {
  __typename: "BatteriesOrganization";
  id: Scalars["String"]["output"];
  plants: Array<Maybe<Plant>>;
};

// export type BatteryAvailabilityPlanInput = {
//   availablePower: InputMaybe<Scalars["Float"]["input"]>;
//   from: Scalars["String"]["input"];
//   to: Scalars["String"]["input"];
// };

export type BatteryAvailabilityPlanInput = {
  availablePowerUp: InputMaybe<Scalars["Float"]["input"]>;
  availablePowerDown: InputMaybe<Scalars["Float"]["input"]>;
  from: Scalars["String"]["input"];
  to: Scalars["String"]["input"];
};

export type BatteryAvailabilityPlanResponse = {
  __typename: "BatteryAvailabilityPlanResponse";
  errors: Maybe<Array<Maybe<BatteryAvailabilityPlanValidationError>>>;
  success: Scalars["Boolean"]["output"];
};

export type BatteryAvailabilityPlanValidationError = {
  __typename: "BatteryAvailabilityPlanValidationError";
  line: Maybe<Scalars["Int"]["output"]>;
  translationKeys: Array<Scalars["String"]["output"]>;
};

export type BatteryAvailabilityPlanValue = {
  __typename: "BatteryAvailabilityPlanValue";
  power: Scalars["Float"]["output"];
  timestamp: Scalars["String"]["output"];
};

export type Claim = {
  __typename: "Claim";
  id: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type ClaimInput = {
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type ClientAdministration = {
  __typename: "ClientAdministration";
  emailWhitelist: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  organization: Organization;
  organizations: Array<Organization>;
  plant: Maybe<Plant>;
  plants: Maybe<Array<Maybe<Plant>>>;
  validMeasurandIds: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type ClientAdministrationEmailWhitelistArgs = {
  organizationId: Scalars["String"]["input"];
};

export type ClientAdministrationOrganizationArgs = {
  id: Scalars["String"]["input"];
};

export type ClientAdministrationPlantArgs = {
  id: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
};

export type ClientAdministrationPlantsArgs = {
  organizationId: Scalars["String"]["input"];
};

export type Comments = {
  __typename: "Comments";
  comment1Values: Array<Scalars["String"]["output"]>;
  comment2Values: Array<Scalars["String"]["output"]>;
};

export type Contact = {
  __typename: "Contact";
  email: Maybe<Scalars["String"]["output"]>;
  fileUrl: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  functionTitle: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  organizationConnections: Maybe<Array<Maybe<OrganizationConnection>>>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
};

export type ContactInput = {
  functionTitle: InputMaybe<Scalars["String"]["input"]>;
  userId: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactPerson = {
  __typename: "ContactPerson";
  email: Maybe<Scalars["String"]["output"]>;
  fileUrl: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  function: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  userId: Maybe<Scalars["String"]["output"]>;
};

export type CookieConsent = {
  __typename: "CookieConsent";
  contentType: Maybe<Scalars["String"]["output"]>;
  fileName: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type Coordinates = {
  __typename: "Coordinates";
  id: Maybe<Scalars["String"]["output"]>;
  latitude: Maybe<Scalars["Float"]["output"]>;
  longitude: Maybe<Scalars["Float"]["output"]>;
};

export type CreateUserInput = {
  email: InputMaybe<Scalars["String"]["input"]>;
  firstName: InputMaybe<Scalars["String"]["input"]>;
  lastName: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  role: InputMaybe<Scalars["Int"]["input"]>;
};

export type CurrencyResult = {
  __typename: "CurrencyResult";
  currency: Maybe<Scalars["String"]["output"]>;
  outcome: Maybe<Array<Maybe<Outcome>>>;
  summary: Maybe<Summary>;
};

export type DataGroup = {
  __typename: "DataGroup";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  periodSize: Maybe<PeriodSize>;
  priceArea: Maybe<Scalars["String"]["output"]>;
};

export type DataGroupOrganization = {
  __typename: "DataGroupOrganization";
  dataGroups: Array<DataGroup>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type Document = {
  __typename: "Document";
  createdBy: Maybe<Scalars["String"]["output"]>;
  createdDate: Maybe<Scalars["String"]["output"]>;
  fileName: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  modifiedBy: Maybe<Scalars["String"]["output"]>;
  modifiedDate: Maybe<Scalars["String"]["output"]>;
  organization: Maybe<Scalars["String"]["output"]>;
};

export type ElcQuotaExemption = {
  __typename: "ElcQuotaExemption";
  finalFixedVolume: Maybe<Scalars["Float"]["output"]>;
  finalVariableVolume: Maybe<Scalars["Float"]["output"]>;
  preliminaryFixedVolume: Maybe<Scalars["Float"]["output"]>;
  preliminaryVariableVolume: Maybe<Scalars["Float"]["output"]>;
  typeId: Scalars["Int"]["output"];
  typeName: Maybe<Scalars["String"]["output"]>;
};

export type ElcQuotaExemptionInput = {
  finalFixedVolume: InputMaybe<Scalars["Float"]["input"]>;
  finalVariableVolume: InputMaybe<Scalars["Float"]["input"]>;
  preliminaryFixedVolume: InputMaybe<Scalars["Float"]["input"]>;
  preliminaryVariableVolume: InputMaybe<Scalars["Float"]["input"]>;
  typeId: Scalars["Int"]["input"];
  typeName: InputMaybe<Scalars["String"]["input"]>;
};

export type ElcWithService = {
  __typename: "ElcWithService";
  buyPriceAvg: Maybe<Scalars["Float"]["output"]>;
  buyQuantity: Maybe<Scalars["Float"]["output"]>;
  consumptionVolume: Maybe<Scalars["Float"]["output"]>;
  month: Maybe<Scalars["Int"]["output"]>;
  quotaObligationExemptedVolume: Maybe<Scalars["Float"]["output"]>;
  quotaObligationVolume: Maybe<Scalars["Float"]["output"]>;
  quotaPercent: Maybe<Scalars["Float"]["output"]>;
  quotaQuantity: Maybe<Scalars["Float"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type ElcWithoutService = {
  __typename: "ElcWithoutService";
  buyForecastPercent: Maybe<Scalars["Float"]["output"]>;
  buyPriceAvg: Maybe<Scalars["Float"]["output"]>;
  buyQuantity: Maybe<Scalars["Float"]["output"]>;
  forecast: Maybe<Scalars["Float"]["output"]>;
  importedBuyPriceAvg: Maybe<Scalars["Float"]["output"]>;
  importedBuyQuantity: Maybe<Scalars["Float"]["output"]>;
  importedPriceAvg: Maybe<Scalars["Float"]["output"]>;
  importedQuantity: Maybe<Scalars["Float"]["output"]>;
  importedSellPriceAvg: Maybe<Scalars["Float"]["output"]>;
  importedSellQuantity: Maybe<Scalars["Float"]["output"]>;
  quotaPercent: Maybe<Scalars["Float"]["output"]>;
  quotaQuantity: Maybe<Scalars["Float"]["output"]>;
  remainingPercent: Maybe<Scalars["Float"]["output"]>;
  remainingQuantity: Maybe<Scalars["Float"]["output"]>;
  sellForecastPercent: Maybe<Scalars["Float"]["output"]>;
  sellPriceAvg: Maybe<Scalars["Float"]["output"]>;
  sellQuantity: Maybe<Scalars["Float"]["output"]>;
  totalPrice: Maybe<Scalars["Float"]["output"]>;
  totalPriceAvg: Maybe<Scalars["Float"]["output"]>;
  totalQuantity: Maybe<Scalars["Float"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type EnergyMeasurand = {
  __typename: "EnergyMeasurand";
  name: Maybe<Scalars["String"]["output"]>;
  series: EnergySeries;
};

export type EnergyMonthlyStandardsSummary = {
  __typename: "EnergyMonthlyStandardsSummary";
  consumptionHourlyPriceArea1: Maybe<Scalars["Float"]["output"]>;
  consumptionHourlyPriceArea2: Maybe<Scalars["Float"]["output"]>;
  consumptionHourlyPriceArea3: Maybe<Scalars["Float"]["output"]>;
  consumptionHourlyPriceArea4: Maybe<Scalars["Float"]["output"]>;
  consumptionMonthly: Maybe<Scalars["Float"]["output"]>;
  consumptionMonthlyAndHourly: Maybe<Scalars["Float"]["output"]>;
  consumptionTotal: Maybe<Scalars["Float"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  month: Maybe<Scalars["String"]["output"]>;
  monthIndex: Maybe<Scalars["Int"]["output"]>;
  productionHourlyPriceArea1: Maybe<Scalars["Float"]["output"]>;
  productionHourlyPriceArea2: Maybe<Scalars["Float"]["output"]>;
  productionHourlyPriceArea3: Maybe<Scalars["Float"]["output"]>;
  productionHourlyPriceArea4: Maybe<Scalars["Float"]["output"]>;
  productionTotal: Maybe<Scalars["Float"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
};

export type EnergyMonthlySummary = {
  __typename: "EnergyMonthlySummary";
  /** Total based on actual and standards */
  consumption: Maybe<Scalars["Float"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  month: Maybe<Scalars["String"]["output"]>;
  monthIndex: Maybe<Scalars["Int"]["output"]>;
  /** Total based on actual and standards */
  production: Maybe<Scalars["Float"]["output"]>;
  standards: EnergyMonthlyStandardsSummary;
  unit: Maybe<Scalars["String"]["output"]>;
};

export type EnergyOrganization = {
  __typename: "EnergyOrganization";
  aggregate: Array<Maybe<EnergySeries>>;
  monthlySummary: Array<Maybe<EnergyMonthlySummary>>;
  plants: Array<Maybe<EnergyPlantListItem>>;
  standard: Array<Maybe<EnergySeries>>;
};

export type EnergyOrganizationAggregateArgs = {
  filter: InputMaybe<Scalars["String"]["input"]>;
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type EnergyOrganizationMonthlySummaryArgs = {
  year: Scalars["Int"]["input"];
};

export type EnergyOrganizationPlantsArgs = {
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type EnergyOrganizationStandardArgs = {
  filter: InputMaybe<Scalars["String"]["input"]>;
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type EnergyPlant = {
  __typename: "EnergyPlant";
  aggregate: Array<Maybe<EnergySeries>>;
  consumption: Maybe<Scalars["Float"]["output"]>;
  measurands: Array<Maybe<EnergyMeasurand>>;
  monthlySummary: Array<Maybe<EnergyMonthlySummary>>;
  production: Maybe<Scalars["Float"]["output"]>;
};

export type EnergyPlantAggregateArgs = {
  filter: InputMaybe<Scalars["String"]["input"]>;
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type EnergyPlantConsumptionArgs = {
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type EnergyPlantMeasurandsArgs = {
  filter: InputMaybe<Scalars["String"]["input"]>;
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type EnergyPlantMonthlySummaryArgs = {
  year: Scalars["Int"]["input"];
};

export type EnergyPlantProductionArgs = {
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type EnergyPlantListItem = {
  __typename: "EnergyPlantListItem";
  consumption: Maybe<Scalars["Float"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  production: Maybe<Scalars["Float"]["output"]>;
};

export type EnergyReading = {
  __typename: "EnergyReading";
  time: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type EnergySeries = {
  __typename: "EnergySeries";
  readings: Array<Maybe<EnergyReading>>;
  type: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
};

export type ExchangeRates = {
  __typename: "ExchangeRates";
  name: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type FeeInput = {
  factor: Scalars["Float"]["input"];
  id: Scalars["Int"]["input"];
};

export type FinancialInvoiceMaterial = {
  __typename: "FinancialInvoiceMaterial";
  id: Scalars["Int"]["output"];
  insertionDate: Maybe<Scalars["String"]["output"]>;
  month: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
};

export type Forecast = {
  __typename: "Forecast";
  month: Scalars["Int"]["output"];
  priceAreas: Array<PriceArea>;
  volume: Scalars["Float"]["output"];
  year: Scalars["Int"]["output"];
};

export type ForecastInput = {
  month: Scalars["Int"]["input"];
  priceAreas: Array<InputMaybe<ForecastPriceArea>>;
  volume: Scalars["Float"]["input"];
  year: Scalars["Int"]["input"];
};

export type ForecastPriceArea = {
  priceArea: Scalars["String"]["input"];
  value: Scalars["Float"]["input"];
};

export type ForwardCurve = {
  __typename: "ForwardCurve";
  name: Scalars["String"]["output"];
  values: Array<YearForward>;
};

export type GoConsumption = {
  __typename: "GoConsumption";
  clientId: Scalars["Int"]["output"];
  clientName: Maybe<Scalars["String"]["output"]>;
  finalFixedVolume: Maybe<Scalars["Float"]["output"]>;
  finalVariableVolume: Maybe<Scalars["Float"]["output"]>;
  hasFixedVolume: Maybe<Scalars["Boolean"]["output"]>;
  preliminaryFixedVolume: Maybe<Scalars["Float"]["output"]>;
  preliminaryVariableVolume: Maybe<Scalars["Float"]["output"]>;
  productionType: Maybe<Scalars["String"]["output"]>;
};

export type GoConsumptionInput = {
  clientId: InputMaybe<Scalars["Int"]["input"]>;
  clientName: InputMaybe<Scalars["String"]["input"]>;
  finalFixedVolume: InputMaybe<Scalars["Float"]["input"]>;
  finalVariableVolume: InputMaybe<Scalars["Float"]["input"]>;
  hasFixedVolume: InputMaybe<Scalars["Boolean"]["input"]>;
  preliminaryFixedVolume: InputMaybe<Scalars["Float"]["input"]>;
  preliminaryVariableVolume: InputMaybe<Scalars["Float"]["input"]>;
  productionType: InputMaybe<Scalars["String"]["input"]>;
};

export type GoSummary = {
  __typename: "GoSummary";
  marketPrices: Maybe<Array<Maybe<MarketPrices>>>;
  marketQuantities: Maybe<Array<Maybe<MarketQuantities>>>;
  totalPrice: Maybe<Scalars["Float"]["output"]>;
  totalQuantity: Maybe<Scalars["Float"]["output"]>;
  totalUnitPrice: Maybe<Scalars["Float"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type GoSummaryExcel = {
  __typename: "GoSummaryExcel";
  content: Maybe<Scalars["String"]["output"]>;
  contentType: Maybe<Scalars["String"]["output"]>;
  encoding: Maybe<Scalars["String"]["output"]>;
  filename: Maybe<Scalars["String"]["output"]>;
};

export type GoWithoutService = {
  __typename: "GoWithoutService";
  buyForecastPercent: Maybe<Scalars["Float"]["output"]>;
  buyPriceAvg: Maybe<Scalars["Float"]["output"]>;
  buyQuantity: Maybe<Scalars["Float"]["output"]>;
  forecast: Maybe<Scalars["Float"]["output"]>;
  importedBuyPriceAvg: Maybe<Scalars["Float"]["output"]>;
  importedBuyQuantity: Maybe<Scalars["Float"]["output"]>;
  importedPriceAvg: Maybe<Scalars["Float"]["output"]>;
  importedQuantity: Maybe<Scalars["Float"]["output"]>;
  importedSellPriceAvg: Maybe<Scalars["Float"]["output"]>;
  importedSellQuantity: Maybe<Scalars["Float"]["output"]>;
  remainingPercent: Maybe<Scalars["Float"]["output"]>;
  remainingQuantity: Maybe<Scalars["Float"]["output"]>;
  sellForecastPercent: Maybe<Scalars["Float"]["output"]>;
  sellPriceAvg: Maybe<Scalars["Float"]["output"]>;
  sellQuantity: Maybe<Scalars["Float"]["output"]>;
  totalPrice: Maybe<Scalars["Float"]["output"]>;
  totalPriceAvg: Maybe<Scalars["Float"]["output"]>;
  totalQuantity: Maybe<Scalars["Float"]["output"]>;
  year: Maybe<Scalars["Float"]["output"]>;
};

export type Hedge = {
  __typename: "Hedge";
  agreementPrice: Scalars["Float"]["output"];
  elcAgreementPrice: Scalars["Float"]["output"];
  elcVolume: Scalars["Float"]["output"];
  forecast: Scalars["Float"]["output"];
  goAgreementPrice: Scalars["Float"]["output"];
  goVolume: Scalars["Float"]["output"];
  hedgePercentage: Scalars["Float"]["output"];
  hedgeVolume: Scalars["Float"]["output"];
  mandateMaxPercentage: Maybe<Scalars["Float"]["output"]>;
  mandateMaxVolume: Maybe<Scalars["Float"]["output"]>;
  mandateMinPercentage: Maybe<Scalars["Float"]["output"]>;
  mandateMinVolume: Maybe<Scalars["Float"]["output"]>;
  marketPrice: Scalars["Float"]["output"];
  openVolume: Scalars["Float"]["output"];
  outcome: Scalars["Float"]["output"];
  period: Scalars["String"]["output"];
  portfolioPrice: Scalars["Float"]["output"];
  pricePerUnit: Scalars["Float"]["output"];
  settlementUnits: Scalars["Float"]["output"];
  targetVolume: Scalars["Float"]["output"];
  totalAgreementPrice: Scalars["Float"]["output"];
  utilizedVolume: Scalars["Float"]["output"];
};

export type HedgePeriod = {
  month: Scalars["Int"]["input"];
  value: Scalars["Float"]["input"];
  year: Scalars["Int"]["input"];
};

export type Hedges = {
  __typename: "Hedges";
  hedgeReportType: Scalars["String"]["output"];
  hedges: Array<Hedge>;
  products: Array<Scalars["String"]["output"]>;
  resolution: Scalars["String"]["output"];
};

export type HeroImage = {
  __typename: "HeroImage";
  description: Maybe<Scalars["String"]["output"]>;
  desktop: Maybe<Image>;
  id: Maybe<Scalars["String"]["output"]>;
  mobile: Maybe<Image>;
  tablet: Maybe<Image>;
};

export type HourlyPrice = {
  __typename: "HourlyPrice";
  date: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  price: Maybe<Scalars["Float"]["output"]>;
};

export type Image = {
  __typename: "Image";
  description: Maybe<Scalars["String"]["output"]>;
  fileUrl: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  imageHeight: Maybe<Scalars["Int"]["output"]>;
  imageWidth: Maybe<Scalars["Int"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type ImpersonatedRole = {
  __typename: "ImpersonatedRole";
  organizationName: Maybe<Scalars["String"]["output"]>;
  roleName: Maybe<Scalars["String"]["output"]>;
};

export type ImportElcPositionInput = {
  comment: InputMaybe<Scalars["String"]["input"]>;
  contact: InputMaybe<Scalars["String"]["input"]>;
  contract: InputMaybe<Scalars["String"]["input"]>;
  counterpart: Scalars["String"]["input"];
  currency: Scalars["String"]["input"];
  deliveryDate: InputMaybe<Scalars["String"]["input"]>;
  fee: InputMaybe<Scalars["Float"]["input"]>;
  fromDate: Scalars["String"]["input"];
  id: InputMaybe<Scalars["String"]["input"]>;
  price: InputMaybe<Scalars["Float"]["input"]>;
  productName: Scalars["String"]["input"];
  quantity: InputMaybe<Scalars["Float"]["input"]>;
  toDate: Scalars["String"]["input"];
  tradeDate: InputMaybe<Scalars["String"]["input"]>;
  unit: InputMaybe<Scalars["String"]["input"]>;
};

export type ImportGoPositionInput = {
  comment: InputMaybe<Scalars["String"]["input"]>;
  contact: InputMaybe<Scalars["String"]["input"]>;
  contract: InputMaybe<Scalars["String"]["input"]>;
  counterpart: Scalars["String"]["input"];
  currency: Scalars["String"]["input"];
  deliveryDate: InputMaybe<Scalars["String"]["input"]>;
  fee: InputMaybe<Scalars["Float"]["input"]>;
  fromDate: Scalars["String"]["input"];
  id: InputMaybe<Scalars["String"]["input"]>;
  price: InputMaybe<Scalars["Float"]["input"]>;
  productName: Scalars["String"]["input"];
  quantity: InputMaybe<Scalars["Float"]["input"]>;
  toDate: Scalars["String"]["input"];
  tradeDate: InputMaybe<Scalars["String"]["input"]>;
  unit: InputMaybe<Scalars["String"]["input"]>;
};

export type ImportPositionInput = {
  comment: InputMaybe<Scalars["String"]["input"]>;
  comment2: InputMaybe<Scalars["String"]["input"]>;
  contact: InputMaybe<Scalars["String"]["input"]>;
  contract: InputMaybe<Scalars["String"]["input"]>;
  counterpart: Scalars["String"]["input"];
  currency: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  hedgeFee: InputMaybe<Scalars["Float"]["input"]>;
  price: InputMaybe<Scalars["Float"]["input"]>;
  productName: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
  tradeDate: InputMaybe<Scalars["String"]["input"]>;
  unit: InputMaybe<Scalars["String"]["input"]>;
  volume: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImportedElcPosition = {
  __typename: "ImportedElcPosition";
  comment: Maybe<Scalars["String"]["output"]>;
  contact: Maybe<Scalars["String"]["output"]>;
  contract: Maybe<Scalars["String"]["output"]>;
  counterpart: Maybe<Scalars["String"]["output"]>;
  created: Maybe<Scalars["String"]["output"]>;
  createdBy: Maybe<Scalars["String"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  deliveryDate: Maybe<Scalars["String"]["output"]>;
  fee: Maybe<Scalars["Float"]["output"]>;
  fromDate: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  price: Maybe<Scalars["Float"]["output"]>;
  productName: Maybe<Scalars["String"]["output"]>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  toDate: Maybe<Scalars["String"]["output"]>;
  tradeDate: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
};

export type ImportedGoPosition = {
  __typename: "ImportedGoPosition";
  comment: Maybe<Scalars["String"]["output"]>;
  contact: Maybe<Scalars["String"]["output"]>;
  contract: Maybe<Scalars["String"]["output"]>;
  counterpart: Maybe<Scalars["String"]["output"]>;
  created: Maybe<Scalars["String"]["output"]>;
  createdBy: Maybe<Scalars["String"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  deliveryDate: Maybe<Scalars["String"]["output"]>;
  fee: Maybe<Scalars["Float"]["output"]>;
  fromDate: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  price: Maybe<Scalars["Float"]["output"]>;
  productName: Maybe<Scalars["String"]["output"]>;
  quantity: Maybe<Scalars["Float"]["output"]>;
  toDate: Maybe<Scalars["String"]["output"]>;
  tradeDate: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
};

export type ImportedPosition = {
  __typename: "ImportedPosition";
  comment: Maybe<Scalars["String"]["output"]>;
  comment2: Maybe<Scalars["String"]["output"]>;
  contact: Maybe<Scalars["String"]["output"]>;
  contract: Maybe<Scalars["String"]["output"]>;
  counterpart: Scalars["String"]["output"];
  created: Scalars["String"]["output"];
  createdBy: Scalars["String"]["output"];
  currency: Scalars["String"]["output"];
  fromDate: Scalars["String"]["output"];
  hedgeFee: Maybe<Scalars["Float"]["output"]>;
  price: Maybe<Scalars["Float"]["output"]>;
  productName: Scalars["String"]["output"];
  toDate: Scalars["String"]["output"];
  tradeDate: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
  volume: Maybe<Scalars["Float"]["output"]>;
};

export type IncomingDataPlanResponse = {
  __typename: "IncomingDataPlanResponse";
  name: Scalars["String"]["output"];
  periodSize: PeriodSize;
  values: Maybe<Array<Maybe<IncomingDataPlanValue>>>;
};

export type IncomingDataPlanValue = {
  __typename: "IncomingDataPlanValue";
  timestamp: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export type IndexOrder = {
  __typename: "IndexOrder";
  createdDate: Maybe<Scalars["String"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  forecastVolume: Maybe<Scalars["Float"]["output"]>;
  hedgedPercentageOfForecast: Maybe<Scalars["Float"]["output"]>;
  hedgedPercentageOfTarget: Maybe<Scalars["Float"]["output"]>;
  hedgedVolume: Maybe<Scalars["Float"]["output"]>;
  hedgingEndDate: Maybe<Scalars["String"]["output"]>;
  hedgingStartDate: Maybe<Scalars["String"]["output"]>;
  portfolioId: Maybe<Scalars["String"]["output"]>;
  portfolioName: Maybe<Scalars["String"]["output"]>;
  positionType: Maybe<Scalars["String"]["output"]>;
  price: Maybe<Scalars["Float"]["output"]>;
  product: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  targetVolume: Maybe<Scalars["Float"]["output"]>;
  tradingEndDate: Maybe<Scalars["String"]["output"]>;
  tradingStartDate: Maybe<Scalars["String"]["output"]>;
};

export type IndexPool = {
  __typename: "IndexPool";
  currency: Maybe<Scalars["String"]["output"]>;
  deliveryEnd: Maybe<Scalars["String"]["output"]>;
  deliveryStart: Maybe<Scalars["String"]["output"]>;
  hedgePeriodEnd: Maybe<Scalars["String"]["output"]>;
  hedgePeriodStart: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  indexPool: Maybe<Scalars["String"]["output"]>;
  key: Maybe<Scalars["String"]["output"]>;
  priceArea: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type IndexPoolHedge = {
  __typename: "IndexPoolHedge";
  agreementPrice: Maybe<Scalars["Float"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  forecast: Maybe<Scalars["Float"]["output"]>;
  hedgePercentage: Maybe<Scalars["Float"]["output"]>;
  hedgeVolume: Maybe<Scalars["Float"]["output"]>;
  mandateMaxPercentage: Maybe<Scalars["Float"]["output"]>;
  mandateMaxVolume: Maybe<Scalars["Float"]["output"]>;
  mandateMinPercentage: Maybe<Scalars["Float"]["output"]>;
  mandateMinVolume: Maybe<Scalars["Float"]["output"]>;
  marketPrice: Maybe<Scalars["Float"]["output"]>;
  openVolume: Maybe<Scalars["Float"]["output"]>;
  outcome: Maybe<Scalars["Float"]["output"]>;
  period: Maybe<Scalars["String"]["output"]>;
  periodTranslation: Maybe<Scalars["String"]["output"]>;
  portfolioPrice: Maybe<Scalars["Float"]["output"]>;
  pricePerUnit: Maybe<Scalars["Float"]["output"]>;
  settlementUnits: Maybe<Scalars["Float"]["output"]>;
  targetVolume: Maybe<Scalars["Float"]["output"]>;
  utilizedVolume: Maybe<Scalars["Float"]["output"]>;
};

export type IndexPoolHedgeReport = {
  __typename: "IndexPoolHedgeReport";
  hedges: Maybe<Array<Maybe<IndexPoolHedge>>>;
  products: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  resolution: Maybe<Scalars["String"]["output"]>;
};

export type IndexPoolInvoiceMaterial = {
  __typename: "IndexPoolInvoiceMaterial";
  currency: Maybe<Scalars["String"]["output"]>;
  deliveryMonth: Maybe<Scalars["String"]["output"]>;
  firstDeliveryMonth: Maybe<Scalars["String"]["output"]>;
  hedgedPrice: Maybe<Scalars["Float"]["output"]>;
  hedgedVolume: Maybe<Scalars["Float"]["output"]>;
  indexPoolType: Maybe<Scalars["String"]["output"]>;
  outcome: Maybe<Scalars["Float"]["output"]>;
  priceArea: Maybe<Scalars["String"]["output"]>;
  spotPrice: Maybe<Scalars["Float"]["output"]>;
  targetVolume: Maybe<Scalars["Float"]["output"]>;
};

export type IndexProductPortfolio = {
  __typename: "IndexProductPortfolio";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type IndexProductPortfolioInput = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
};

export type Invoice = {
  __typename: "Invoice";
  amount: Maybe<Scalars["Float"]["output"]>;
  currencyCode: Maybe<Scalars["String"]["output"]>;
  date: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  invoiceNumber: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type InvoiceMaterials = {
  __typename: "InvoiceMaterials";
  financial: Maybe<Array<Maybe<FinancialInvoiceMaterial>>>;
  physical: Maybe<Array<Maybe<PhysicalInvoiceMaterial>>>;
};

export type IssueApiToken = {
  __typename: "IssueApiToken";
  expires: Maybe<Scalars["String"]["output"]>;
  jti: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  token: Maybe<Scalars["String"]["output"]>;
};

export type Mandate = {
  month: Scalars["Int"]["input"];
  priceAreas: Array<InputMaybe<ForecastPriceArea>>;
  volume: Scalars["Float"]["input"];
  year: Scalars["Int"]["input"];
};

export type MandateHedge = {
  __typename: "MandateHedge";
  month: Maybe<Scalars["Int"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type MandateInput = {
  priceAreas: Array<InputMaybe<Scalars["String"]["input"]>>;
  tradePeriodsIndex: InputMaybe<Array<InputMaybe<TradePeriod>>>;
  tradePeriodsMax: InputMaybe<Array<InputMaybe<TradePeriod>>>;
  tradePeriodsMin: InputMaybe<Array<InputMaybe<TradePeriod>>>;
};

export type MandatePeriod = {
  __typename: "MandatePeriod";
  priceAreas: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  tradePeriodsIndex: Maybe<Array<Maybe<MandateTradePeriod>>>;
  tradePeriodsMax: Maybe<Array<Maybe<MandateTradePeriod>>>;
  tradePeriodsMin: Maybe<Array<Maybe<MandateTradePeriod>>>;
};

export type MandateTradePeriod = {
  __typename: "MandateTradePeriod";
  hedgePeriods: Maybe<Array<Maybe<MandateHedge>>>;
  month: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type MarketPrices = {
  __typename: "MarketPrices";
  productionType: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type MarketQuantities = {
  __typename: "MarketQuantities";
  productionType: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type Measurand = {
  __typename: "Measurand";
  belongsToThirdParty: Maybe<Scalars["Boolean"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  isGenerated: Maybe<Scalars["Boolean"]["output"]>;
  measurandId: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
};

export type MeasurandInput = {
  belongsToThirdParty: InputMaybe<Scalars["Boolean"]["input"]>;
  measurandId: InputMaybe<Scalars["String"]["input"]>;
  measurandType: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  unit: InputMaybe<Scalars["String"]["input"]>;
};

export type ModityUser = {
  __typename: "ModityUser";
  email: Maybe<Scalars["String"]["output"]>;
  fileUrl: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  role: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename: "Mutation";
  addImpersonation: Maybe<Scalars["String"]["output"]>;
  addPhysicalFeePeriod: Maybe<Scalars["String"]["output"]>;
  createContact: Maybe<Scalars["Boolean"]["output"]>;
  createEmailWhitelistEntry: Maybe<Scalars["Boolean"]["output"]>;
  createForecast: Maybe<Scalars["String"]["output"]>;
  createMandate: Maybe<Scalars["String"]["output"]>;
  createMeasurand: Maybe<Scalars["Boolean"]["output"]>;
  createOrUpdateImportedElcPositions: Maybe<Scalars["String"]["output"]>;
  createOrUpdateImportedGoPositions: Maybe<Scalars["String"]["output"]>;
  createOrUpdateImportedPositions: Maybe<Scalars["String"]["output"]>;
  createOrganization: Maybe<Scalars["Boolean"]["output"]>;
  createPlant: Maybe<Scalars["Boolean"]["output"]>;
  createPortfolioReportConfiguration: Maybe<Scalars["String"]["output"]>;
  createProductionConsumptionDataGroup: Maybe<Scalars["String"]["output"]>;
  createRole: Maybe<Scalars["Boolean"]["output"]>;
  createTestOrganization: Maybe<Scalars["Boolean"]["output"]>;
  createUser: Maybe<Scalars["Boolean"]["output"]>;
  deleteDataGroup: Maybe<Scalars["String"]["output"]>;
  deleteEmailWhitelistEntry: Maybe<Scalars["Boolean"]["output"]>;
  deleteForecast: Maybe<Scalars["String"]["output"]>;
  deleteImpersonation: Maybe<Scalars["String"]["output"]>;
  deleteImportedElcPositions: Maybe<Scalars["String"]["output"]>;
  deleteImportedGoPositions: Maybe<Scalars["String"]["output"]>;
  deleteImportedPositions: Maybe<Scalars["String"]["output"]>;
  deleteMandate: Maybe<Scalars["String"]["output"]>;
  deleteMeasurand: Maybe<Scalars["String"]["output"]>;
  deleteOrganization: Maybe<Scalars["String"]["output"]>;
  deletePhysicalFeePeriod: Maybe<Scalars["String"]["output"]>;
  deletePlant: Maybe<Scalars["String"]["output"]>;
  deletePortfolioReportConfiguration: Maybe<Scalars["String"]["output"]>;
  deleteRole: Maybe<Scalars["Int"]["output"]>;
  deleteTestOrganizations: Maybe<Scalars["Boolean"]["output"]>;
  deleteUser: Maybe<Scalars["String"]["output"]>;
  deleteUserFromOrganization: Maybe<Scalars["String"]["output"]>;
  issueApiToken: Maybe<IssueApiToken>;
  revokeApiToken: Maybe<Scalars["Boolean"]["output"]>;
  saveAvailability: Maybe<Scalars["Boolean"]["output"]>;
  saveBatteryAvailability: Maybe<BatteryAvailabilityPlanResponse>;
  selectOrganization: Maybe<Scalars["String"]["output"]>;
  updateContact: Maybe<Scalars["Boolean"]["output"]>;
  updateCurrentUser: Maybe<Scalars["String"]["output"]>;
  updateGoConsumption: Maybe<Scalars["Boolean"]["output"]>;
  updateMeasurand: Maybe<Scalars["String"]["output"]>;
  updateOrganization: Maybe<Scalars["String"]["output"]>;
  updatePhysicalFees: Maybe<Scalars["String"]["output"]>;
  updatePlant: Maybe<Scalars["String"]["output"]>;
  updatePortfolioReportConfiguration: Maybe<Scalars["String"]["output"]>;
  updateProductionConsumptionDataGroup: Maybe<Scalars["String"]["output"]>;
  updateRole: Maybe<Scalars["Int"]["output"]>;
  updateUser: Maybe<Scalars["Boolean"]["output"]>;
  uploadProductionConsumptionPlans: Maybe<Scalars["Boolean"]["output"]>;
};

export type MutationAddImpersonationArgs = {
  roleId: Scalars["Int"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationAddPhysicalFeePeriodArgs = {
  fromDate: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
};

export type MutationCreateContactArgs = {
  contact: InputMaybe<ContactInput>;
  organizationIds: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type MutationCreateEmailWhitelistEntryArgs = {
  organizationId: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationCreateForecastArgs = {
  configurationId: Scalars["String"]["input"];
  forecasts: Array<InputMaybe<ForecastInput>>;
  indexPoolId: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["String"]["input"];
};

export type MutationCreateMandateArgs = {
  configurationId: Scalars["String"]["input"];
  mandates: MandateInput;
  organizationId: Scalars["String"]["input"];
};

export type MutationCreateMeasurandArgs = {
  measurand: MeasurandInput;
  organizationId: Scalars["String"]["input"];
  plantId: Scalars["String"]["input"];
};

export type MutationCreateOrUpdateImportedElcPositionsArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  positions: Array<ImportElcPositionInput>;
};

export type MutationCreateOrUpdateImportedGoPositionsArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  positions: Array<ImportGoPositionInput>;
};

export type MutationCreateOrUpdateImportedPositionsArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  positions: Array<ImportPositionInput>;
};

export type MutationCreateOrganizationArgs = {
  organization: OrganizationInput;
};

export type MutationCreatePlantArgs = {
  organizationId: Scalars["String"]["input"];
  plant: PlantInput;
};

export type MutationCreatePortfolioReportConfigurationArgs = {
  organizationId: Scalars["String"]["input"];
  portfolioReportConfiguration: PortfolioReportConfigurationInput;
};

export type MutationCreateProductionConsumptionDataGroupArgs = {
  organizationId: Scalars["String"]["input"];
  productionConsumptionDataGroup: ProductionConsumptionDataGroupInput;
};

export type MutationCreateRoleArgs = {
  organizationId: Scalars["String"]["input"];
  role: RoleInput;
};

export type MutationCreateTestOrganizationArgs = {
  isBalanceController: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  userEmail: Scalars["String"]["input"];
};

export type MutationCreateUserArgs = {
  organizationId: Scalars["String"]["input"];
  user: CreateUserInput;
};

export type MutationDeleteDataGroupArgs = {
  groupId: Scalars["Int"]["input"];
  organizationId: Scalars["String"]["input"];
};

export type MutationDeleteEmailWhitelistEntryArgs = {
  organizationId: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationDeleteForecastArgs = {
  configurationId: Scalars["String"]["input"];
  forecastId: Scalars["String"]["input"];
};

export type MutationDeleteImpersonationArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationDeleteImportedElcPositionsArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  positions: Array<ImportElcPositionInput>;
};

export type MutationDeleteImportedGoPositionsArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  positions: Array<ImportGoPositionInput>;
};

export type MutationDeleteImportedPositionsArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  positions: Array<ImportPositionInput>;
};

export type MutationDeleteMandateArgs = {
  configurationId: Scalars["String"]["input"];
  mandateId: Scalars["String"]["input"];
};

export type MutationDeleteMeasurandArgs = {
  measurandId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  plantId: Scalars["String"]["input"];
};

export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars["String"]["input"];
};

export type MutationDeletePhysicalFeePeriodArgs = {
  organizationId: Scalars["String"]["input"];
};

export type MutationDeletePlantArgs = {
  organizationId: Scalars["String"]["input"];
  plantId: Scalars["String"]["input"];
};

export type MutationDeletePortfolioReportConfigurationArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
};

export type MutationDeleteRoleArgs = {
  organizationId: Scalars["String"]["input"];
  roleId: Scalars["Int"]["input"];
};

export type MutationDeleteTestOrganizationsArgs = {
  prefix: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationDeleteUserArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationDeleteUserFromOrganizationArgs = {
  organizationId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationIssueApiTokenArgs = {
  name: InputMaybe<Scalars["String"]["input"]>;
  organizationId: Scalars["String"]["input"];
};

export type MutationRevokeApiTokenArgs = {
  jti: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
};

export type MutationSaveAvailabilityArgs = {
  organizationId: Scalars["String"]["input"];
  plans: Array<InputMaybe<PlanInput>>;
  plantId: Scalars["String"]["input"];
};

export type MutationSaveBatteryAvailabilityArgs = {
  organizationId: Scalars["String"]["input"];
  plans: Array<InputMaybe<BatteryAvailabilityPlanInput>>;
  plantId: Scalars["String"]["input"];
};

export type MutationSelectOrganizationArgs = {
  organizationId: Scalars["String"]["input"];
};

export type MutationUpdateContactArgs = {
  contact: InputMaybe<UpdateContactInput>;
};

export type MutationUpdateCurrentUserArgs = {
  user: InputMaybe<UpdateUserInput>;
  userId: Scalars["String"]["input"];
};

export type MutationUpdateGoConsumptionArgs = {
  elcQuotaExemptions: InputMaybe<Array<InputMaybe<ElcQuotaExemptionInput>>>;
  goConsumption: InputMaybe<Array<InputMaybe<GoConsumptionInput>>>;
  id: Scalars["String"]["input"];
  month: Scalars["String"]["input"];
};

export type MutationUpdateMeasurandArgs = {
  measurand: MeasurandInput;
  measurandId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  plantId: Scalars["String"]["input"];
};

export type MutationUpdateOrganizationArgs = {
  organization: OrganizationInput;
  organizationId: Scalars["String"]["input"];
};

export type MutationUpdatePhysicalFeesArgs = {
  fees: InputMaybe<Array<InputMaybe<FeeInput>>>;
  organizationId: Scalars["String"]["input"];
};

export type MutationUpdatePlantArgs = {
  organizationId: Scalars["String"]["input"];
  plant: PlantInput;
  plantId: Scalars["String"]["input"];
};

export type MutationUpdatePortfolioReportConfigurationArgs = {
  configurationId: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  portfolioReportConfiguration: PortfolioReportConfigurationInput;
};

export type MutationUpdateProductionConsumptionDataGroupArgs = {
  groupId: Scalars["Int"]["input"];
  organizationId: Scalars["String"]["input"];
  productionConsumptionDataGroup: ProductionConsumptionDataGroupInput;
};

export type MutationUpdateRoleArgs = {
  organizationId: Scalars["String"]["input"];
  role: RoleInput;
  roleId: Scalars["Int"]["input"];
};

export type MutationUpdateUserArgs = {
  organizationId: Scalars["String"]["input"];
  roleId: Scalars["Int"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationUploadProductionConsumptionPlansArgs = {
  dataGroupId: Scalars["Int"]["input"];
  organizationId: Scalars["String"]["input"];
  plans: Array<ProductionConsumptionPlanInput>;
};

export type Notification = {
  __typename: "Notification";
  content: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type Organization = {
  __typename: "Organization";
  article: Maybe<Article>;
  articles: Maybe<Array<Maybe<Article>>>;
  availabilityPlanTemplate: Maybe<Scalars["String"]["output"]>;
  availableClaims: Array<Claim>;
  availableConfigurationOptions: Maybe<AvailableOptions>;
  batteryAvailabilityPlanEnabled: Scalars["Boolean"]["output"];
  bfusCustomerId: Maybe<Scalars["String"]["output"]>;
  category: Maybe<Scalars["String"]["output"]>;
  clientType: Maybe<Scalars["String"]["output"]>;
  comments: Comments;
  documents: Maybe<Array<Maybe<Document>>>;
  ediel: Maybe<Scalars["String"]["output"]>;
  elcQuotaExemptions: Maybe<Array<Maybe<ElcQuotaExemption>>>;
  elcStartDate: Maybe<Scalars["String"]["output"]>;
  elcWithService: Maybe<Array<Maybe<ElcWithService>>>;
  elcWithoutService: Maybe<Array<Maybe<ElcWithoutService>>>;
  emailWhitelist: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  energy: EnergyOrganization;
  featuredArticle: Maybe<Article>;
  forwards: Maybe<Array<ForwardCurve>>;
  goConsumption: Maybe<Array<Maybe<GoConsumption>>>;
  goStartDate: Maybe<Scalars["String"]["output"]>;
  goSummary: Maybe<GoSummary>;
  goSummaryExcel: Maybe<GoSummaryExcel>;
  goWithoutService: Maybe<Array<Maybe<GoWithoutService>>>;
  hedgeReport: Array<Hedges>;
  id: Scalars["String"]["output"];
  indexOrders: Maybe<Array<Maybe<IndexOrder>>>;
  indexPoolHedgeReport: Maybe<Array<Maybe<IndexPoolHedgeReport>>>;
  indexPoolInvoiceMaterial: Maybe<Array<Maybe<IndexPoolInvoiceMaterial>>>;
  indexPools: Maybe<Array<Maybe<IndexPool>>>;
  invoice: Maybe<Invoice>;
  invoiceMaterials: Maybe<InvoiceMaterials>;
  invoices: Maybe<Array<Maybe<Invoice>>>;
  mandateUpdateHistory: Maybe<Array<Maybe<UpdateHistoryPost>>>;
  name: Scalars["String"]["output"];
  navCustomerId: Maybe<Scalars["String"]["output"]>;
  nrOfPlants: Maybe<Scalars["Int"]["output"]>;
  organizationNumber: Scalars["String"]["output"];
  outcomes: Maybe<Results>;
  physicalFees: Maybe<Array<Maybe<PhysicalFee>>>;
  plant: Plant;
  plants: Array<Plant>;
  portfolio: Portfolio;
  portfolioReportConfiguration: Maybe<PortfolioReportConfiguration>;
  portfolioReportConfigurations: Array<PortfolioReportConfiguration>;
  products: Array<Product>;
  roles: Array<Role>;
  spotPriceAreas: Array<SpotPriceArea>;
  spotPriceTrends: Array<SpotPriceArea>;
  transactions: Maybe<TransactionsAndProducts>;
  userConnection: UserOrganizationConnection;
  userConnections: Array<Maybe<UserOrganizationConnection>>;
};

export type OrganizationArticleArgs = {
  filterType: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationArticlesArgs = {
  filterFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterType?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationCommentsArgs = {
  currencies: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  importedPosition: InputMaybe<Scalars["Int"]["input"]>;
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  productName: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationElcQuotaExemptionsArgs = {
  month: Scalars["String"]["input"];
};

export type OrganizationFeaturedArticleArgs = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationGoConsumptionArgs = {
  month: Scalars["String"]["input"];
};

export type OrganizationGoSummaryArgs = {
  year: Scalars["Int"]["input"];
};

export type OrganizationGoSummaryExcelArgs = {
  language: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type OrganizationGoWithoutServiceArgs = {
  productionTypes: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type OrganizationHedgeReportArgs = {
  comment1: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  comment2: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  currencies: Array<InputMaybe<Scalars["String"]["input"]>>;
  importedPosition: InputMaybe<Scalars["Int"]["input"]>;
  pointInTime: Scalars["String"]["input"];
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  productName: Scalars["String"]["input"];
};

export type OrganizationIndexOrdersArgs = {
  currency: InputMaybe<Scalars["Int"]["input"]>;
  endDate: InputMaybe<Scalars["String"]["input"]>;
  portfolioReportConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  startDate: Scalars["String"]["input"];
};

export type OrganizationIndexPoolHedgeReportArgs = {
  currencies: Array<InputMaybe<Scalars["String"]["input"]>>;
  indexPoolIds: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  pointInTime: InputMaybe<Scalars["String"]["input"]>;
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  productName: Scalars["String"]["input"];
};

export type OrganizationIndexPoolInvoiceMaterialArgs = {
  deliveryMonth: Scalars["String"]["input"];
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type OrganizationIndexPoolsArgs = {
  fromDate: InputMaybe<Scalars["String"]["input"]>;
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type OrganizationInvoiceArgs = {
  id: Scalars["String"]["input"];
};

export type OrganizationInvoicesArgs = {
  fromDate: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
  toDate: Scalars["String"]["input"];
};

export type OrganizationMandateUpdateHistoryArgs = {
  configurationId: Scalars["String"]["input"];
};

export type OrganizationOutcomesArgs = {
  month: Scalars["String"]["input"];
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type OrganizationPlantArgs = {
  id: Scalars["String"]["input"];
};

export type OrganizationPortfolioReportConfigurationArgs = {
  id: Scalars["String"]["input"];
};

export type OrganizationProductsArgs = {
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type OrganizationTransactionsArgs = {
  fromDate: InputMaybe<Scalars["String"]["input"]>;
  portfolioConfigurationIds: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  toDate: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationUserConnectionArgs = {
  userId: Scalars["String"]["input"];
};

export type OrganizationConnection = {
  __typename: "OrganizationConnection";
  functionTitle: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
};

export type OrganizationInput = {
  availabilityPlanTemplate: InputMaybe<Scalars["String"]["input"]>;
  batteryAvailabilityPlanEnabled: InputMaybe<Scalars["Boolean"]["input"]>;
  bfusCustomerId: InputMaybe<Scalars["String"]["input"]>;
  clientType: InputMaybe<Scalars["String"]["input"]>;
  ediel: InputMaybe<Scalars["String"]["input"]>;
  elcStartDate: InputMaybe<Scalars["String"]["input"]>;
  goStartDate: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  navCustomerId: InputMaybe<Scalars["String"]["input"]>;
  organizationNumber: InputMaybe<Scalars["String"]["input"]>;
};

export type Outcome = {
  __typename: "Outcome";
  agreementPrice: Maybe<Scalars["Float"]["output"]>;
  outcome: Maybe<Scalars["Float"]["output"]>;
  product: Maybe<Scalars["String"]["output"]>;
  settlementPrice: Maybe<Scalars["Float"]["output"]>;
  spotPrice: Maybe<Scalars["Float"]["output"]>;
  totalVolume: Maybe<Scalars["Float"]["output"]>;
};

export type OutcomeSummary = {
  __typename: "OutcomeSummary";
  currency: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export enum PeriodSize {
  H1 = "H1",
  M15 = "M15",
}

export type PhysicalFee = {
  __typename: "PhysicalFee";
  id: Scalars["Int"]["output"];
  rules: Maybe<Array<Maybe<PhysicalFeeRule>>>;
  validFrom: Scalars["String"]["output"];
  validTo: Maybe<Scalars["String"]["output"]>;
};

export type PhysicalFeeRule = {
  __typename: "PhysicalFeeRule";
  factor: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["Int"]["output"];
  type: Scalars["String"]["output"];
};

export type PhysicalInvoiceMaterial = {
  __typename: "PhysicalInvoiceMaterial";
  month: Maybe<Scalars["String"]["output"]>;
};

export type PlanInput = {
  comment: InputMaybe<Scalars["String"]["input"]>;
  curtailment: InputMaybe<Scalars["Float"]["input"]>;
  fromTimestamp: Scalars["String"]["input"];
  ice: InputMaybe<Scalars["Float"]["input"]>;
  power: InputMaybe<Scalars["Float"]["input"]>;
  toTimestamp: Scalars["String"]["input"];
};

export type Plant = {
  __typename: "Plant";
  coordinates: Maybe<Coordinates>;
  energy: EnergyPlant;
  facilityId: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isGenerated: Maybe<Scalars["Boolean"]["output"]>;
  maxPower: Scalars["Float"]["output"];
  measurands: Maybe<Array<Maybe<Measurand>>>;
  name: Scalars["String"]["output"];
  nrOfMeasurands: Maybe<Scalars["Int"]["output"]>;
  priceArea: Scalars["String"]["output"];
  spotPrices: SpotPrices;
  timeZone: Maybe<Scalars["String"]["output"]>;
  weather: Maybe<Weather>;
};

export type PlantSpotPricesArgs = {
  currency: InputMaybe<Scalars["String"]["input"]>;
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type PlantWeatherArgs = {
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type PlantInput = {
  facilityId: InputMaybe<Scalars["String"]["input"]>;
  latitude: InputMaybe<Scalars["Float"]["input"]>;
  longitude: InputMaybe<Scalars["Float"]["input"]>;
  maxPower: Scalars["Float"]["input"];
  name: InputMaybe<Scalars["String"]["input"]>;
  priceArea: InputMaybe<Scalars["String"]["input"]>;
  timeZone: InputMaybe<Scalars["String"]["input"]>;
};

export type Portfolio = {
  __typename: "Portfolio";
  id: Maybe<Scalars["String"]["output"]>;
  physical: PortfolioPhysical;
  summaries: Array<Maybe<PortfolioTotal>>;
};

export type PortfolioPhysicalArgs = {
  month: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type PortfolioInput = {
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
};

export type PortfolioMonthlyTotal = {
  __typename: "PortfolioMonthlyTotal";
  amount: Maybe<Scalars["Float"]["output"]>;
  fee: Maybe<Scalars["Float"]["output"]>;
  financialOutcome: Maybe<Scalars["Float"]["output"]>;
  financialVolume: Maybe<Scalars["Float"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  month: Maybe<Scalars["String"]["output"]>;
  totalAmount: Maybe<Scalars["Float"]["output"]>;
  unitPrice: Maybe<Scalars["Float"]["output"]>;
  volume: Maybe<Scalars["Float"]["output"]>;
};

export type PortfolioPhysical = {
  __typename: "PortfolioPhysical";
  consumptionRows: Array<Maybe<PortfolioPhysicalRow>>;
  consumptionTotalAmount: Maybe<Scalars["Float"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  month: Maybe<Scalars["String"]["output"]>;
  productionRows: Array<Maybe<PortfolioPhysicalRow>>;
  productionTotalAmount: Maybe<Scalars["Float"]["output"]>;
};

export type PortfolioPhysicalRow = {
  __typename: "PortfolioPhysicalRow";
  amount: Maybe<Scalars["Float"]["output"]>;
  price: Maybe<Scalars["Float"]["output"]>;
  rowLabel: Maybe<Scalars["String"]["output"]>;
  shouldBeBold: Maybe<Scalars["Boolean"]["output"]>;
  sortOrder: Maybe<Scalars["Float"]["output"]>;
  volume: Maybe<Scalars["Float"]["output"]>;
};

export type PortfolioReportConfiguration = {
  __typename: "PortfolioReportConfiguration";
  allegroCounterparties: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  forecast: Maybe<Array<Maybe<Forecast>>>;
  forecastUpdateHistory: Maybe<UpdateHistoryPostWithLatest>;
  id: Scalars["String"]["output"];
  importedElcPositions: Maybe<Array<Maybe<ImportedElcPosition>>>;
  importedGoPositions: Maybe<Array<Maybe<ImportedGoPosition>>>;
  importedPositions: Maybe<Array<Maybe<ImportedPosition>>>;
  indexProductPortfolios: Maybe<Array<Maybe<IndexProductPortfolio>>>;
  mandate: Maybe<MandatePeriod>;
  measurandIds: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name: Scalars["String"]["output"];
  portfolios: Maybe<Array<Maybe<PortfolioReportConfigurationPortfolio>>>;
  thirdPartyPortfolios: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type PortfolioReportConfigurationForecastArgs = {
  forecastId: Scalars["String"]["input"];
};

export type PortfolioReportConfigurationMandateArgs = {
  mandateId: Scalars["String"]["input"];
};

export type PortfolioReportConfigurationInput = {
  allegroCounterparties: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  indexProductPortfolios: InputMaybe<
    Array<InputMaybe<IndexProductPortfolioInput>>
  >;
  measurandIds: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name: Scalars["String"]["input"];
  portfolios: InputMaybe<Array<InputMaybe<PortfolioInput>>>;
  thirdPartyPortfolios: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  type: InputMaybe<Scalars["String"]["input"]>;
};

export type PortfolioReportConfigurationPortfolio = {
  __typename: "PortfolioReportConfigurationPortfolio";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  type: Maybe<Scalars["String"]["output"]>;
};

export type PortfolioTotal = {
  __typename: "PortfolioTotal";
  consumption: Array<Maybe<PortfolioMonthlyTotal>>;
  currency: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  production: Array<Maybe<PortfolioMonthlyTotal>>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type PriceArea = {
  __typename: "PriceArea";
  priceArea: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type PriceAreas = {
  __typename: "PriceAreas";
  financial: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type Product = {
  __typename: "Product";
  currencies: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type ProductionConsumptionDataGroupInput = {
  consumptionMfuId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  periodSize: PeriodSize;
  priceArea: Scalars["String"]["input"];
  productionMfuId: Scalars["String"]["input"];
};

export type ProductionConsumptionDataGroupResponse = {
  __typename: "ProductionConsumptionDataGroupResponse";
  consumption: TimeSeriesDefintion;
  name: Scalars["String"]["output"];
  priceArea: Scalars["String"]["output"];
  production: TimeSeriesDefintion;
};

export type ProductionConsumptionPlanInput = {
  consumption: Scalars["Float"]["input"];
  fromTimestamp: Scalars["String"]["input"];
  production: Scalars["Float"]["input"];
  toTimestamp: Scalars["String"]["input"];
};

export type Query = {
  __typename: "Query";
  agreement: Maybe<Agreement>;
  apiTokens: Maybe<Array<Maybe<TokenInformation>>>;
  availableFeatures: Maybe<AvailableFeatures>;
  availableUsers: Maybe<Array<Maybe<ModityUser>>>;
  clientAdministration: ClientAdministration;
  contacts: Maybe<Array<Maybe<ContactPerson>>>;
  cookieConsent: Maybe<CookieConsent>;
  currentContacts: Maybe<Array<Maybe<Contact>>>;
  currentUser: User;
  dailyComments: Maybe<Array<Maybe<Notification>>>;
  getAvailabilityPlansForBattery: Array<Maybe<BatteryAvailabilityPlanValue>>;
  getBatteriesForOrganization: BatteriesOrganization;
  incomingDataPlan: Array<Maybe<IncomingDataPlanResponse>>;
  notifications: Maybe<Array<Maybe<Notification>>>;
  organization: Organization;
  organizationDataGroups: DataGroupOrganization;
  priceAreas: Maybe<PriceAreas>;
  priceAreasWeatherToday: Maybe<Array<Maybe<Weather>>>;
  productionConsumptionDataGroup: ProductionConsumptionDataGroupResponse;
};

export type QueryAgreementArgs = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryAvailableUsersArgs = {
  filter: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryContactsArgs = {
  organizationId: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCookieConsentArgs = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDailyCommentsArgs = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAvailabilityPlansForBatteryArgs = {
  dateFrom: Scalars["String"]["input"];
  dateTo: Scalars["String"]["input"];
  organization: Scalars["String"]["input"];
  plant: Scalars["String"]["input"];
};

export type QueryGetBatteriesForOrganizationArgs = {
  organizationId: Scalars["String"]["input"];
};

export type QueryIncomingDataPlanArgs = {
  from: Scalars["String"]["input"];
  groupId: Scalars["Int"]["input"];
  organizationId: Scalars["String"]["input"];
  to: Scalars["String"]["input"];
};

export type QueryNotificationsArgs = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryOrganizationArgs = {
  id: Scalars["String"]["input"];
};

export type QueryOrganizationDataGroupsArgs = {
  id: Scalars["String"]["input"];
};

export type QueryProductionConsumptionDataGroupArgs = {
  groupId: Scalars["Int"]["input"];
  organizationId: Scalars["String"]["input"];
};

export type Results = {
  __typename: "Results";
  currencyResults: Maybe<Array<Maybe<CurrencyResult>>>;
  totalResult: Maybe<TotalResult>;
};

export type Role = {
  __typename: "Role";
  claims: Maybe<Array<Maybe<Claim>>>;
  id: Maybe<Scalars["Int"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
};

export type RoleInput = {
  claims: Array<InputMaybe<ClaimInput>>;
  name: Scalars["String"]["input"];
};

export type SpotPrice = {
  __typename: "SpotPrice";
  price: Scalars["Float"]["output"];
  time: Scalars["String"]["output"];
};

export type SpotPriceArea = {
  __typename: "SpotPriceArea";
  area: Scalars["String"]["output"];
  areaDescription: Scalars["String"]["output"];
  currency: Scalars["String"]["output"];
  spotPrices: Array<SpotPrice>;
};

export type SpotPrices = {
  __typename: "SpotPrices";
  area: Maybe<Scalars["String"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  hourlyPrices: Maybe<Array<Maybe<HourlyPrice>>>;
  id: Maybe<Scalars["String"]["output"]>;
};

export type Summary = {
  __typename: "Summary";
  outcome: Maybe<Scalars["Float"]["output"]>;
  totalVolume: Maybe<Scalars["Float"]["output"]>;
};

export type TextArticle = Article & {
  __typename: "TextArticle";
  author: Maybe<Author>;
  content: Maybe<Scalars["String"]["output"]>;
  contentType: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  featured: Maybe<Scalars["Boolean"]["output"]>;
  firstPublishedAt: Maybe<Scalars["String"]["output"]>;
  heroImage: Maybe<HeroImage>;
  heroThumbnail: Maybe<Image>;
  id: Maybe<Scalars["String"]["output"]>;
  preamble: Maybe<Scalars["String"]["output"]>;
  publicationStartDate: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["String"]["output"]>;
};

export type TimeSeriesDefintion = {
  __typename: "TimeSeriesDefintion";
  id: Scalars["String"]["output"];
  mfuId: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  periodSize: PeriodSize;
};

export type TokenInformation = {
  __typename: "TokenInformation";
  expires: Maybe<Scalars["String"]["output"]>;
  issueDate: Maybe<Scalars["String"]["output"]>;
  jti: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  revokedDate: Maybe<Scalars["String"]["output"]>;
};

export type TotalOutcome = {
  __typename: "TotalOutcome";
  currency: Maybe<Scalars["String"]["output"]>;
  outcome: Maybe<Scalars["Float"]["output"]>;
};

export type TotalResult = {
  __typename: "TotalResult";
  exchangeRates: Maybe<Array<Maybe<ExchangeRates>>>;
  outcome: Maybe<Array<Maybe<TotalOutcome>>>;
  outcomeSummary: Maybe<Array<Maybe<OutcomeSummary>>>;
};

export type TradePeriod = {
  hedgePeriods: InputMaybe<Array<InputMaybe<HedgePeriod>>>;
  month: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type Transaction = {
  __typename: "Transaction";
  comment1: Maybe<Scalars["String"]["output"]>;
  comment2: Maybe<Scalars["String"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  endDate: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  mWh: Maybe<Scalars["Float"]["output"]>;
  mw: Maybe<Scalars["Float"]["output"]>;
  outcome: Maybe<Scalars["Float"]["output"]>;
  price: Maybe<Scalars["Float"]["output"]>;
  product: Maybe<Scalars["String"]["output"]>;
  startDate: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  tradeDate: Maybe<Scalars["String"]["output"]>;
};

export type TransactionsAndProducts = {
  __typename: "TransactionsAndProducts";
  products: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  transactions: Maybe<Array<Maybe<Transaction>>>;
};

export type UpdateContactInput = {
  contactFunction: InputMaybe<Scalars["String"]["input"]>;
  organizationsToAdd: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  organizationsToRemove: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  userId: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateHistoryPost = {
  __typename: "UpdateHistoryPost";
  created: Scalars["String"]["output"];
  createdBy: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  indexPoolId: Maybe<Scalars["Int"]["output"]>;
};

export type UpdateHistoryPostWithLatest = {
  __typename: "UpdateHistoryPostWithLatest";
  forecastUpdateHistory: Maybe<Array<Maybe<UpdateHistoryPost>>>;
  latestUpdate: Maybe<Array<Maybe<Forecast>>>;
};

export type UpdateUserInput = {
  avatar: InputMaybe<Scalars["String"]["input"]>;
  email: InputMaybe<Scalars["String"]["input"]>;
  firstName: InputMaybe<Scalars["String"]["input"]>;
  imageMimeType: InputMaybe<Scalars["String"]["input"]>;
  lastName: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: InputMaybe<Scalars["String"]["input"]>;
  showDailyComment: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type User = {
  __typename: "User";
  active: Maybe<Scalars["Boolean"]["output"]>;
  avatar: Maybe<Image>;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  impersonatedRole: Maybe<ImpersonatedRole>;
  lastName: Maybe<Scalars["String"]["output"]>;
  organizationConnections: Array<UserOrganizationConnection>;
  organizationCount: Maybe<Scalars["Int"]["output"]>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  selectedOrganizationConnection: UserOrganizationConnection;
  showDailyComment: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserOrganizationConnection = {
  __typename: "UserOrganizationConnection";
  hasApiToken: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  organization: Organization;
  role: Role;
  user: User;
};

export type VideoArticle = Article & {
  __typename: "VideoArticle";
  author: Maybe<Author>;
  content: Maybe<Scalars["String"]["output"]>;
  contentType: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  featured: Maybe<Scalars["Boolean"]["output"]>;
  firstPublishedAt: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  preamble: Maybe<Scalars["String"]["output"]>;
  publicationStartDate: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["String"]["output"]>;
  videoId: Maybe<Scalars["String"]["output"]>;
};

export type Weather = {
  __typename: "Weather";
  code: Maybe<Scalars["String"]["output"]>;
  temperature: WeatherData;
  wind: Maybe<WeatherData>;
};

export type WeatherData = {
  __typename: "WeatherData";
  /** Distance in kilometers from the plant */
  distance: Maybe<Scalars["Int"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  readings: Array<WeatherDataReading>;
  unit: Maybe<Scalars["String"]["output"]>;
};

export type WeatherDataReading = {
  __typename: "WeatherDataReading";
  time: Scalars["String"]["output"];
  value: Maybe<Scalars["Float"]["output"]>;
};

export type WebinarArticle = Article & {
  __typename: "WebinarArticle";
  author: Maybe<Author>;
  content: Maybe<Scalars["String"]["output"]>;
  contentType: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  featured: Maybe<Scalars["Boolean"]["output"]>;
  firstPublishedAt: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  preamble: Maybe<Scalars["String"]["output"]>;
  publicationStartDate: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["String"]["output"]>;
  videoId: Maybe<Scalars["String"]["output"]>;
};

export type YearForward = {
  __typename: "YearForward";
  price: Scalars["Float"]["output"];
  year: Scalars["Int"]["output"];
};

export type PriceAreaCoordinates = {
  latitude: InputMaybe<Scalars["Float"]["input"]>;
  longitude: InputMaybe<Scalars["Float"]["input"]>;
};

export type CreateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename: "Mutation";
  createOrganization: boolean | null;
};

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type DeleteOrganizationMutation = {
  __typename: "Mutation";
  deleteOrganization: string | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  organization: OrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename: "Mutation";
  updateOrganization: string | null;
};

export type SaveBatteryAvailabilityMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  plantId: Scalars["String"]["input"];
  plans:
    | Array<InputMaybe<BatteryAvailabilityPlanInput>>
    | InputMaybe<BatteryAvailabilityPlanInput>;
}>;

export type SaveBatteryAvailabilityMutation = {
  __typename: "Mutation";
  saveBatteryAvailability: {
    __typename: "BatteryAvailabilityPlanResponse";
    success: boolean;
    errors: Array<{
      __typename: "BatteryAvailabilityPlanValidationError";
      line: number | null;
      translationKeys: Array<string>;
    } | null> | null;
  } | null;
};

export type CreateProductionConsumptionDataGroupMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  productionConsumptionDataGroup: ProductionConsumptionDataGroupInput;
}>;

export type CreateProductionConsumptionDataGroupMutation = {
  __typename: "Mutation";
  createProductionConsumptionDataGroup: string | null;
};

export type DeleteDataGroupMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  groupId: Scalars["Int"]["input"];
}>;

export type DeleteDataGroupMutation = {
  __typename: "Mutation";
  deleteDataGroup: string | null;
};

export type UpdateProductionConsumptionDataGroupMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  groupId: Scalars["Int"]["input"];
  productionConsumptionDataGroup: ProductionConsumptionDataGroupInput;
}>;

export type UpdateProductionConsumptionDataGroupMutation = {
  __typename: "Mutation";
  updateProductionConsumptionDataGroup: string | null;
};

export type UploadProductionConsumptionPlansMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  dataGroupId: Scalars["Int"]["input"];
  plans: Array<ProductionConsumptionPlanInput> | ProductionConsumptionPlanInput;
}>;

export type UploadProductionConsumptionPlansMutation = {
  __typename: "Mutation";
  uploadProductionConsumptionPlans: boolean | null;
};

export type SaveAvailabilityMutationVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  plantId: Scalars["String"]["input"];
  plans: Array<InputMaybe<PlanInput>> | InputMaybe<PlanInput>;
}>;

export type SaveAvailabilityMutation = {
  __typename: "Mutation";
  saveAvailability: boolean | null;
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetOrganizationQuery = {
  __typename: "Query";
  clientAdministration: {
    __typename: "ClientAdministration";
    organization: {
      __typename: "Organization";
      id: string;
      name: string;
      organizationNumber: string;
      navCustomerId: string | null;
      bfusCustomerId: string | null;
      category: string | null;
      ediel: string | null;
      nrOfPlants: number | null;
      clientType: string | null;
      elcStartDate: string | null;
      goStartDate: string | null;
      availabilityPlanTemplate: string | null;
      batteryAvailabilityPlanEnabled: boolean;
      portfolioReportConfigurations: Array<{
        __typename: "PortfolioReportConfiguration";
        id: string;
        name: string;
      }>;
    };
  };
};

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationsQuery = {
  __typename: "Query";
  clientAdministration: {
    __typename: "ClientAdministration";
    organizations: Array<{
      __typename: "Organization";
      id: string;
      name: string;
    }>;
  };
};

export type GetAvailabilityPlansForBatteryQueryVariables = Exact<{
  organization: Scalars["String"]["input"];
  plant: Scalars["String"]["input"];
  dateFrom: Scalars["String"]["input"];
  dateTo: Scalars["String"]["input"];
}>;

export type GetAvailabilityPlansForBatteryQuery = {
  __typename: "Query";
  getAvailabilityPlansForBattery: Array<{
    __typename: "BatteryAvailabilityPlanValue";
    power: number;
    timestamp: string;
  } | null>;
};

export type GetBatteriesForOrganizationQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type GetBatteriesForOrganizationQuery = {
  __typename: "Query";
  organization: {
    __typename: "Organization";
    id: string;
    plants: Array<{ __typename: "Plant"; id: string; name: string }>;
  };
};

export type GetDataGroupsQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type GetDataGroupsQuery = {
  __typename: "Query";
  organizationDataGroups: {
    __typename: "DataGroupOrganization";
    id: string;
    name: string;
    dataGroups: Array<{
      __typename: "DataGroup";
      id: number;
      name: string;
      priceArea: string | null;
      periodSize: PeriodSize | null;
    }>;
  };
};

export type GetIncomingDataPlanQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  groupId: Scalars["Int"]["input"];
  from: Scalars["String"]["input"];
  to: Scalars["String"]["input"];
}>;

export type GetIncomingDataPlanQuery = {
  __typename: "Query";
  incomingDataPlan: Array<{
    __typename: "IncomingDataPlanResponse";
    name: string;
    periodSize: PeriodSize;
    values: Array<{
      __typename: "IncomingDataPlanValue";
      timestamp: string;
      value: number;
    } | null> | null;
  } | null>;
};

export type GetProductionConsumptionDataGroupQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  groupId: Scalars["Int"]["input"];
}>;

export type GetProductionConsumptionDataGroupQuery = {
  __typename: "Query";
  productionConsumptionDataGroup: {
    __typename: "ProductionConsumptionDataGroupResponse";
    name: string;
    priceArea: string;
    consumption: {
      __typename: "TimeSeriesDefintion";
      id: string;
      name: string;
      mfuId: string;
      periodSize: PeriodSize;
    };
    production: {
      __typename: "TimeSeriesDefintion";
      id: string;
      name: string;
      mfuId: string;
      periodSize: PeriodSize;
    };
  };
};

export type GetLatestSpotPricesQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type GetLatestSpotPricesQuery = {
  __typename: "Query";
  organization: {
    __typename: "Organization";
    id: string;
    spotPriceAreas: Array<{
      __typename: "SpotPriceArea";
      area: string;
      currency: string;
      areaDescription: string;
      spotPrices: Array<{
        __typename: "SpotPrice";
        time: string;
        price: number;
      }>;
    }>;
  };
  priceAreasWeatherToday: Array<{
    __typename: "Weather";
    code: string | null;
    temperature: {
      __typename: "WeatherData";
      name: string | null;
      unit: string | null;
      distance: number | null;
      readings: Array<{
        __typename: "WeatherDataReading";
        time: string;
        value: number | null;
      }>;
    };
    wind: {
      __typename: "WeatherData";
      name: string | null;
      unit: string | null;
      distance: number | null;
      readings: Array<{
        __typename: "WeatherDataReading";
        time: string;
        value: number | null;
      }>;
    } | null;
  } | null> | null;
};

export type GetPlantsForAvailabilityQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type GetPlantsForAvailabilityQuery = {
  __typename: "Query";
  organization: {
    __typename: "Organization";
    id: string;
    availabilityPlanTemplate: string | null;
    plants: Array<{
      __typename: "Plant";
      id: string;
      name: string;
      maxPower: number;
      coordinates: {
        __typename: "Coordinates";
        id: string | null;
        latitude: number | null;
        longitude: number | null;
      } | null;
    }>;
  };
};

export type GetPriceTrendsQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type GetPriceTrendsQuery = {
  __typename: "Query";
  organization: {
    __typename: "Organization";
    id: string;
    spotPriceTrends: Array<{
      __typename: "SpotPriceArea";
      area: string;
      currency: string;
      areaDescription: string;
      spotPrices: Array<{
        __typename: "SpotPrice";
        time: string;
        price: number;
      }>;
    }>;
    forwards: Array<{
      __typename: "ForwardCurve";
      name: string;
      values: Array<{ __typename: "YearForward"; year: number; price: number }>;
    }> | null;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename: "Query";
  currentUser: {
    __typename: "User";
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    showDailyComment: boolean | null;
    phoneNumber: string | null;
    active: boolean | null;
    avatar: {
      __typename: "Image";
      id: string | null;
      fileUrl: string | null;
    } | null;
    impersonatedRole: {
      __typename: "ImpersonatedRole";
      roleName: string | null;
      organizationName: string | null;
    } | null;
    selectedOrganizationConnection: {
      __typename: "UserOrganizationConnection";
      organization: {
        __typename: "Organization";
        id: string;
        name: string;
        category: string | null;
      };
      role: {
        __typename: "Role";
        id: number | null;
        name: string | null;
        claims: Array<{
          __typename: "Claim";
          type: string | null;
          value: string | null;
        } | null> | null;
      };
    };
    organizationConnections: Array<{
      __typename: "UserOrganizationConnection";
      organization: { __typename: "Organization"; id: string; name: string };
      role: {
        __typename: "Role";
        id: number | null;
        name: string | null;
        claims: Array<{
          __typename: "Claim";
          type: string | null;
          value: string | null;
        } | null> | null;
      };
    }>;
  };
};

export type GetCommentsQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  portfolioConfigurationIds: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  productName: InputMaybe<Scalars["String"]["input"]>;
  currencies: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  importedPosition: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetCommentsQuery = {
  __typename: "Query";
  organization: {
    __typename: "Organization";
    id: string;
    comments: {
      __typename: "Comments";
      comment1Values: Array<string>;
      comment2Values: Array<string>;
    };
  };
};

export type GetHedgesQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  pointInTime: Scalars["String"]["input"];
  portfolioConfigurationIds: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  productName: Scalars["String"]["input"];
  currencies:
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>;
  importedPosition: InputMaybe<Scalars["Int"]["input"]>;
  comment1: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  comment2: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetHedgesQuery = {
  __typename: "Query";
  organization: {
    __typename: "Organization";
    id: string;
    hedgeReport: Array<{
      __typename: "Hedges";
      products: Array<string>;
      resolution: string;
      hedgeReportType: string;
      hedges: Array<{
        __typename: "Hedge";
        period: string;
        forecast: number;
        hedgeVolume: number;
        utilizedVolume: number;
        agreementPrice: number;
        openVolume: number;
        marketPrice: number;
        outcome: number;
        portfolioPrice: number;
        hedgePercentage: number;
        mandateMinPercentage: number | null;
        mandateMaxPercentage: number | null;
        mandateMinVolume: number | null;
        mandateMaxVolume: number | null;
        settlementUnits: number;
        targetVolume: number;
        pricePerUnit: number;
        goVolume: number;
        goAgreementPrice: number;
        elcVolume: number;
        elcAgreementPrice: number;
        totalAgreementPrice: number;
      }>;
    }>;
  };
};

export type GetProductsQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  portfolioConfigurationIds: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetProductsQuery = {
  __typename: "Query";
  organization: {
    __typename: "Organization";
    id: string;
    products: Array<{
      __typename: "Product";
      name: string;
      currencies: Array<string>;
    }>;
  };
};

export const CreateOrganizationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organization" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "OrganizationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organization" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organization" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const DeleteOrganizationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const UpdateOrganizationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organization" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "OrganizationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organization" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organization" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const SaveBatteryAvailabilityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveBatteryAvailability" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plantId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plans" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "BatteryAvailabilityPlanInput" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveBatteryAvailability" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plantId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plantId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plans" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plans" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "line" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKeys" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveBatteryAvailabilityMutation,
  SaveBatteryAvailabilityMutationVariables
>;
export const CreateProductionConsumptionDataGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createProductionConsumptionDataGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productionConsumptionDataGroup" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "ProductionConsumptionDataGroupInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "createProductionConsumptionDataGroup",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productionConsumptionDataGroup" },
                value: {
                  kind: "Variable",
                  name: {
                    kind: "Name",
                    value: "productionConsumptionDataGroup",
                  },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProductionConsumptionDataGroupMutation,
  CreateProductionConsumptionDataGroupMutationVariables
>;
export const DeleteDataGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteDataGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "groupId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteDataGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "groupId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "groupId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDataGroupMutation,
  DeleteDataGroupMutationVariables
>;
export const UpdateProductionConsumptionDataGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateProductionConsumptionDataGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "groupId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productionConsumptionDataGroup" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "ProductionConsumptionDataGroupInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updateProductionConsumptionDataGroup",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "groupId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "groupId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productionConsumptionDataGroup" },
                value: {
                  kind: "Variable",
                  name: {
                    kind: "Name",
                    value: "productionConsumptionDataGroup",
                  },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProductionConsumptionDataGroupMutation,
  UpdateProductionConsumptionDataGroupMutationVariables
>;
export const UploadProductionConsumptionPlansDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "uploadProductionConsumptionPlans" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataGroupId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plans" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "ProductionConsumptionPlanInput",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadProductionConsumptionPlans" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataGroupId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataGroupId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plans" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plans" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadProductionConsumptionPlansMutation,
  UploadProductionConsumptionPlansMutationVariables
>;
export const SaveAvailabilityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveAvailability" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plantId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plans" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "PlanInput" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveAvailability" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plantId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plantId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plans" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plans" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveAvailabilityMutation,
  SaveAvailabilityMutationVariables
>;
export const GetOrganizationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientAdministration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "navCustomerId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bfusCustomerId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "ediel" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nrOfPlants" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientType" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "portfolioReportConfigurations",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "elcStartDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "goStartDate" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availabilityPlanTemplate",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "batteryAvailabilityPlanEnabled",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const GetOrganizationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getOrganizations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientAdministration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables
>;
export const GetAvailabilityPlansForBatteryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAvailabilityPlansForBattery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organization" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plant" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAvailabilityPlansForBattery" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organization" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organization" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plant" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plant" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateFrom" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFrom" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateTo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateTo" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "power" } },
                { kind: "Field", name: { kind: "Name", value: "timestamp" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailabilityPlansForBatteryQuery,
  GetAvailabilityPlansForBatteryQueryVariables
>;
export const GetBatteriesForOrganizationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getBatteriesForOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "plants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBatteriesForOrganizationQuery,
  GetBatteriesForOrganizationQueryVariables
>;
export const GetDataGroupsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDataGroups" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationDataGroups" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataGroups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceArea" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "periodSize" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataGroupsQuery, GetDataGroupsQueryVariables>;
export const GetIncomingDataPlanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getIncomingDataPlan" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "groupId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "from" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "to" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "incomingDataPlan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "groupId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "groupId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "from" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "from" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "to" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "to" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "periodSize" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "values" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timestamp" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetIncomingDataPlanQuery,
  GetIncomingDataPlanQueryVariables
>;
export const GetProductionConsumptionDataGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProductionConsumptionDataGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "groupId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "productionConsumptionDataGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "groupId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "groupId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "priceArea" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumption" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "mfuId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "periodSize" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "production" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "mfuId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "periodSize" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductionConsumptionDataGroupQuery,
  GetProductionConsumptionDataGroupQueryVariables
>;
export const GetLatestSpotPricesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getLatestSpotPrices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "spotPriceAreas" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "area" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "areaDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "spotPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "time" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "price" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceAreasWeatherToday" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "temperature" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "distance" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "readings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "time" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "wind" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "distance" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "readings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "time" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLatestSpotPricesQuery,
  GetLatestSpotPricesQueryVariables
>;
export const GetPlantsForAvailabilityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPlantsForAvailability" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availabilityPlanTemplate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "plants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "latitude" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "longitude" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "maxPower" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlantsForAvailabilityQuery,
  GetPlantsForAvailabilityQueryVariables
>;
export const GetPriceTrendsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPriceTrends" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "spotPriceTrends" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "area" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "areaDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "spotPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "time" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "price" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "forwards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "values" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "year" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "price" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPriceTrendsQuery, GetPriceTrendsQueryVariables>;
export const GetCurrentUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCurrentUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "showDailyComment" },
                },
                { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "impersonatedRole" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "roleName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationName" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "active" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "selectedOrganizationConnection",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organization" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "role" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "claims" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationConnections" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organization" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "role" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "claims" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "portfolioConfigurationIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currencies" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "importedPosition" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comments" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "portfolioConfigurationIds",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "portfolioConfigurationIds",
                        },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "productName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "productName" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "currencies" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currencies" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "importedPosition" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "importedPosition" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment1Values" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment2Values" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCommentsQuery, GetCommentsQueryVariables>;
export const GetHedgesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getHedges" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pointInTime" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "portfolioConfigurationIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currencies" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "importedPosition" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment1" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment2" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hedgeReport" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "portfolioConfigurationIds",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "portfolioConfigurationIds",
                        },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pointInTime" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pointInTime" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "productName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "productName" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "currencies" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currencies" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "importedPosition" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "importedPosition" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "comment1" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "comment1" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "comment2" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "comment2" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "products" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "resolution" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hedgeReportType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hedges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "period" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "forecast" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hedgeVolume" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "utilizedVolume" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "agreementPrice" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "openVolume" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketPrice" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "outcome" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "portfolioPrice" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hedgePercentage" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "mandateMinPercentage",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "mandateMaxPercentage",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mandateMinVolume" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mandateMaxVolume" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "settlementUnits" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "targetVolume" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pricePerUnit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "goVolume" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "goAgreementPrice" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "elcVolume" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "elcAgreementPrice",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalAgreementPrice",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHedgesQuery, GetHedgesQueryVariables>;
export const GetProductsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProducts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "portfolioConfigurationIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "products" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "portfolioConfigurationIds",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "portfolioConfigurationIds",
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currencies" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductsQuery, GetProductsQueryVariables>;
