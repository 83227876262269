import { BatteryAvailabilityPlanInput } from "src/graphql/graphql";
import { LanguageKey } from "src/config/translations/translationsTypes";

export function createBatteryUploadChartDataset({
  availabilityPlans,
  translate,
}: {
  availabilityPlans: BatteryAvailabilityPlanInput[];
  translate: (value: LanguageKey) => string;
}) {
  return {
    datasets: [
      {
        label: translate("PrequalifiedEffectUp") + " (MW)",
        fill: false,
        borderColor: "#25a772",
        borderWidth: 2,
        backgroundColor: "#25a772",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y",
        spanGaps: true,
        data: availabilityPlans.flatMap((plan) => [
          { x: new Date(plan.from), y: plan.availablePowerUp },
          { x: new Date(plan.to), y: plan.availablePowerUp },
        ]),
      },
      {
        label: translate("PrequalifiedEffectDown") + " (MW)",
        fill: false,
        borderColor: "#2589f1",
        borderWidth: 2,
        backgroundColor: "#2589f1",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y",
        spanGaps: true,
        data: availabilityPlans.flatMap((plan) => [
          { x: new Date(plan.from), y: plan.availablePowerDown },
          { x: new Date(plan.to), y: plan.availablePowerDown },
        ]),
      },
    ],
  };
}
