import styles from "./BatteryAvailabilityVisualizer.module.scss";
import globalStyles from "src/styles/global.module.scss";
import { useEffect, useState } from "react";
import {
  BatteryAvailabilityPlanValue,
  GetAvailabilityPlansForBatteryDocument,
} from "src/graphql/graphql";
import { useQuery } from "@apollo/client";
import { getWeekDateRangeISO } from "../../ProductionConsumption/utils/incomingDataPlanUtils";
import { format, formatISO } from "date-fns";
import { DatePickerInput } from "@mantine/dates";
import "dayjs/locale/en";
import "dayjs/locale/sv";
import { useTranslation } from "src/translations/translationProvider";
import LoadingSpinner from "src/components/loadingSpinner";
import { BatteryAvailabilityChart } from "../BatteryAvailabilityChart/BatteryAvailabilityChart";
import { BatteryAvailabilityTable } from "../BatteryAvailabilityTable/BatteryAvailabilityTable";
export function BatteryAvailabilityVisualizer({
  organization,
  plant,
}: {
  plant: string;
  organization: string;
}) {
  const { locale, translate } = useTranslation();
  const localeOnly = locale.split("-")[0];

  const [plans, setPlans] = useState<BatteryAvailabilityPlanValue[]>([]);

  const initialWeekRange = getWeekDateRangeISO(
    formatISO(new Date().setHours(0, 0, 0, 0)),
  );

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(initialWeekRange.From),
    new Date(initialWeekRange.To),
  ]);

  const { data, loading } = useQuery(GetAvailabilityPlansForBatteryDocument, {
    variables: {
      organization,
      plant,
      dateFrom: dateRange[0]
        ? format(dateRange[0], "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      dateTo: dateRange[1]
        ? format(dateRange[1], "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  useEffect(() => {
    if (data?.getAvailabilityPlansForBattery) {
      setPlans(
        data.getAvailabilityPlansForBattery.filter((plan) => plan !== null),
      );
    }
  }, [data]);

  return (
    <section className={globalStyles.sectionTitleLarge}>
      <DatePickerInput
        type="range"
        label={translate("IncomingPeriod")}
        placeholder={translate("IncomingPeriod")}
        value={
          dateRange.map((date) => (date ? new Date(date) : null)) as [
            Date | null,
            Date | null,
          ]
        }
        onChange={(range) => {
          setDateRange([
            range[0] ? new Date(formatISO(range[0])) : null,
            range[1] ? new Date(formatISO(range[1])) : null,
          ]);
        }}
        locale={localeOnly}
        className={styles.datepicker}
      />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <BatteryAvailabilityChart
            className={styles.chart}
            availabilityPlans={plans}
          />
          <BatteryAvailabilityTable data={plans} />
        </>
      )}
    </section>
  );
}
